<template>
  <v-card class="top-country-list elevation-1">
    <v-card-title>Top Countries by Sessions</v-card-title>
    <v-card-text>
      <v-row no-gutters align="center" class="mb-2 country-header">
        <v-col cols="6">
          <div>Region</div>
        </v-col>
        <v-col cols="3" class="text-right">
          <div>{{ type }}</div>
        </v-col>
        <v-col cols="3" class="text-right">
          <div>Share</div>
        </v-col>
      </v-row>
      <widget-loader v-if="loading" />
      <template v-else>
        <top-country-item
          v-for="(item, idx) in data"
          :key="idx"
          :geo="item.geo"
          :count="item.count"
          :totalCount="totalCount"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed } from "vue";
import WidgetLoader from "./WidgetLoader.vue";
import TopCountryItem from "./TopCountryItem.vue";

const props = defineProps({
  loading: Boolean,
  data: Array,
  type: String,
});

const totalCount = computed(() =>
  props.data.reduce((sum, item) => sum + item.count, 0)
);
</script>

<style scoped>
.top-country-list {
  background: var(--overview-card-bg);
  color: var(--text-color);
}

.country-header {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-color);
}
</style>
