<template>
  <div class="h-screen">
    <div id="app" class="page-container">
      <div class="content-wrapper bg-cover bg-login-brand">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore();
</script>

<style scoped>
#app {
  color: #2c3e50;
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.bg-login-brand {
  background: #131313;
}

.content-wrapper {
  flex: 1;
}
</style>
