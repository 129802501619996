<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Activity Log</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <div
            class="flex flex-column sm:flex-row justify-content-between gap-3"
          >
            <Dropdown
              v-model="typeFilter"
              :options="alertTypes"
              optionValue="typeId"
              optionLabel="typeName"
              placeholder="Filter by Type"
              checkmark
              filter
            />
            <Calendar
              showIcon
              fluid
              iconDisplay="input"
              v-model="dateRange"
              selectionMode="range"
              :manualInput="false"
            >
              <template #inputicon v-if="dateRange != 'Choose Date Range'">
                <i class="pi pi-times cursor-pointer" @click="clearDateRange" />
              </template>
            </Calendar>
          </div>
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            v-model="searchFilter"
            placeholder="Global Search"
          >
            <i class="pi pi-times"></i>
          </InputText>
        </div>
      </template>
      <template #content>
        <div v-if="alertsByDate && alertsByDate.length > 0" class="p-3">
          <div class="activity-log">
            <template v-for="(alerts, index) in alertsByDate" :key="index">
              <div class="activity-date">{{ alerts.day }}</div>
              <ul class="activity-list">
                <li
                  v-for="alert in alerts.alerts"
                  :key="alert.alert_id"
                  class="activity-item"
                >
                  <div
                    class="activity-icon"
                    :style="{
                      backgroundColor: alertColors[alert.alert_type_id],
                    }"
                  ></div>
                  <div class="activity-content">
                    <div class="activity-header">
                      <h4 class="activity-title">{{ alert.title }}</h4>
                      <span class="activity-datetime"
                        >{{ alert.Ago }},
                        {{ formatDateTime(alert.timestamp) }}</span
                      >
                    </div>
                    <p class="activity-text">{{ alert.alert_text }}</p>
                  </div>
                  <i
                    class="pi pi-times cursor-pointer close-icon"
                    @click="handleClose(alert.alert_id)"
                  ></i>
                </li>
              </ul>
            </template>
          </div>
        </div>
        <div v-else class="p-3">
          <p class="text-center">No activities to display</p>
        </div>
        <div v-if="alertsByDate && alertsByDate.length > 0" class="card">
          <Paginator
            class="border-none-paginator"
            v-model:first="first"
            v-model:rows="rows"
            :totalRecords="totalAlertLength"
            :rowsPerPageOptions="[12, 24, 48]"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Paginator from "primevue/paginator";
import { useToast } from "primevue/usetoast";
import moment from "moment";
import InputText from "primevue/inputtext";

const store = useStore();
const toast = useToast();
const token = localStorage.getItem("podcastAdminToken");
const searchFilter = ref("");
const rows = ref(12);
const first = ref(0);
const typeFilter = ref(0);
const dateRange = ref("Choose Date Range");
const alertColors = ref([
  "#1ea97c", // Teal
  "#ff6b6b", // Coral
  "#4a90e2", // Bright Blue
  "#feca57", // Marigold
  "#9b59b6", // Purple
  "#2ecc71", // Emerald Green
  "#e74c3c", // Red
  "#3498db", // Dodger Blue
  "#f39c12", // Orange
  "#1abc9c", // Turquoise
  "#d35400", // Pumpkin
  "#34495e", // Wet Asphalt
  "#27ae60", // Nephritis
  "#e67e22", // Carrot
  "#16a085", // Green Sea
  "#8e44ad", // Wisteria
  "#f1c40f", // Sunflower
  "#c0392b", // Pomegranate
  "#2980b9", // Belize Hole
  "#7f8c8d", // Asbestos
]);

const alertTypes = computed(() => {
  let tempAlertTypes = [];
  let tempAlertTypeOptions = [
    {
      typeId: 0,
      typeName: "All Type",
    },
  ];
  let tempAlerts = store.getters.alerts;
  if (!tempAlerts) {
    tempAlerts = [];
  }
  tempAlerts.forEach((alert) => {
    if (!tempAlertTypes.includes(alert.alert_type_id)) {
      tempAlertTypes.push(alert.alert_type_id);
      let tempStr = alert.alert_type_name.replace(/_/g, " ");
      tempAlertTypeOptions.push({
        typeId: alert.alert_type_id,
        typeName: tempStr,
      });
    }
  });
  return tempAlertTypeOptions;
});

const totalAlertLength = computed(() => {
  return filteredAlerts ? filteredAlerts.value.length : 0;
});

const filteredAlerts = computed(() => {
  let tempAlerts = store.getters.alerts;
  if (!tempAlerts) {
    tempAlerts = [];
  }
  if (typeFilter.value)
    tempAlerts = tempAlerts.filter(
      (alert) => alert.alert_type_id == typeFilter.value
    );
  if (searchFilter.value) {
    tempAlerts = tempAlerts.filter(
      (alert) =>
        alert.alert_type_name
          .toLowerCase()
          .replace(/_/g, " ")
          .search(searchFilter.value.toLowerCase()) !== -1 ||
        alert.alert_text
          .toLowerCase()
          .search(searchFilter.value.toLowerCase()) !== -1
    );
  }
  if (
    dateRange.value != "Choose Data Range" &&
    dateRange.value.length === 2 &&
    dateRange.value[0] != null &&
    dateRange.value[1] == null
  ) {
    tempAlerts = tempAlerts.filter(
      (item) =>
        moment(item.timestamp).isAfter(dateRange.value[0]) &&
        moment(item.timestamp).isBefore(
          moment(dateRange.value[0]).add(1, "days")
        )
    );
  }
  if (
    dateRange.value != "Choose Data Range" &&
    dateRange.value.length === 2 &&
    dateRange.value[0] != null &&
    dateRange.value[1] != null &&
    moment(dateRange.value[0]).isBefore(dateRange.value[1])
  ) {
    tempAlerts = tempAlerts.filter(
      (item) =>
        moment(item.timestamp).isAfter(dateRange.value[0]) &&
        moment(item.timestamp).isBefore(
          moment(dateRange.value[1]).add(1, "days")
        )
    );
  }
  return tempAlerts;
});

const alertsByDate = computed(() => {
  let tempAlerts = filteredAlerts.value;
  if (!tempAlerts) {
    tempAlerts = [];
  }
  tempAlerts = tempAlerts.slice(first.value, first.value + rows.value);
  const grouped = {};
  tempAlerts.forEach((alert) => {
    const day = alert.timestamp.slice(0, 10);
    let title = alert.alert_type_name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    let Ago = timeDifference(alert.timestamp);

    alert = {
      ...alert,
      title,
      Ago,
    };

    if (!grouped[day]) {
      grouped[day] = {
        day: day,
        alerts: [],
      };
    }
    grouped[day].alerts.push(alert);
  });

  return Object.values(grouped);
});

const timeDifference = (timestamp) => {
  const pastDate = new Date(timestamp);
  const now = new Date();

  if (now < pastDate) return "---";

  const years = now.getFullYear() - pastDate.getFullYear();
  if (years > 0) return `${years} Year${years > 1 ? "s" : ""} Ago`;

  const months = now.getMonth() - pastDate.getMonth();
  if (months > 0) return `${months} Month${months > 1 ? "s" : ""} Ago`;

  const days = now.getDate() - pastDate.getDate();
  if (days > 0) return `${days} Day${days > 1 ? "s" : ""} Ago`;

  const hours = now.getHours() - pastDate.getHours();
  if (hours > 0) return `${hours} Hour${hours > 1 ? "s" : ""} Ago`;

  const minutes = now.getMinutes() - pastDate.getMinutes();
  if (minutes > 0) return `${minutes} Minute${minutes > 1 ? "s" : ""} Ago`;

  const seconds = now.getSeconds() - now.getSeconds();
  if (seconds > 0) return `${seconds} Second${seconds > 1 ? "s" : ""} Ago`;

  return "---";
};

const handleClose = async (alertId) => {
  try {
    const response = await axios.delete(API_BASE_URL + "/alert/" + alertId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `1 alert marked as read`,
        life: 4000,
      });
      store.dispatch("loadAlerts", { token });
    } else
      toast.add({
        severity: "error",
        summary: "Can not found",
        life: 4000,
      });
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Server error...",
      life: 4000,
    });
  }
};

const clearDateRange = () => {
  dateRange.value = "Choose Date Range";
};

const fetchAllAlerts = async () => {
  if (!store.getters.alerts || store.getters.alerts.length == 0) {
    store.dispatch("loadAlerts", { token });
  }
};

watch(typeFilter, async (_new, _old) => {
  first.value = 0;
});

watch(dateRange, async (_new, _old) => {
  first.value = 0;
});

watch(searchFilter, async (_new, _old) => {
  first.value = 0;
});

onBeforeMount(() => {
  fetchAllAlerts();
});

const formatDateTime = (timestamp) => {
  return moment(timestamp).format("HH:mm");
};
</script>

<style scoped>
.activity-log {
  position: relative;
  padding-left: 20px;
  font-size: 0.9em;
}

.activity-date {
  font-weight: bold;
  margin-bottom: 8px;
  position: relative;
  font-size: 1em;
}

.activity-date::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  width: 15px;
  height: 2px;
  background-color: #ccc;
}

.activity-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.activity-item {
  position: relative;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
}

.activity-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: 5px;
}

.activity-content {
  flex-grow: 1;
  margin-right: 20px;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.activity-title {
  margin: 0;
  font-size: 0.85em;
}

.activity-datetime {
  font-size: 0.8em;
  color: #666;
}

.activity-text {
  margin: 2px 0;
  font-size: 0.8em;
  color: #b19c9c;
}

.activity-time {
  font-size: 0.75em;
  color: #999;
}

.activity-item::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 5px;
  bottom: -7px;
  width: 1px;
  background-color: #ccc;
}

.activity-item:last-child::before {
  display: none;
}

.close-icon {
  font-size: 0.8em;
  color: #999;
}
</style>
