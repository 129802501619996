<template>
  <Accordion>
    <AccordionTab v-for="api in docData">
      <template #header>
        <div class="flex align-items-center gap-3 flex-wrap">
          <Tag :severity="colorSchemes[api.method.toLowerCase()]">
            {{ api.method }}
          </Tag>
          <code class="font-light text-sm">
            {{ api.route }}
          </code>
          <div class="text-sm">
            {{ api.description }}
          </div>
        </div>
      </template>
      <template #default>
        <div class="flex flex-column gap-3">
          <div
            v-if="api.example?.length"
            class="p-3 surface-100 border-1 border-300 border-round flex flex-column gap-2"
          >
            <div class="text-lg font-bold">Example URL</div>
            <code
              class="surface-50 border-1 border-300 border-round text-700 p-1 px-2 my-1"
            >
              {{ api.example }}
            </code>
          </div>
          <div
            v-if="api.headers?.length"
            class="p-3 surface-100 border-1 border-300 border-round flex flex-column gap-2"
          >
            <div class="text-lg font-bold">Headers</div>
            <DataTable :value="api.headers" class="p-datatable-sm">
              <Column field="name" header="Name"></Column>
              <Column field="description" header="Description"></Column>
              <Column field="example" header="Example"></Column>
            </DataTable>
          </div>
          <div
            v-if="api.params?.length"
            class="p-3 surface-100 border-1 border-300 border-round flex flex-column gap-2"
          >
            <div class="text-lg font-bold">Params</div>
            <DataTable :value="api.params" class="p-datatable-sm">
              <Column field="name" header="Name"></Column>
              <Column field="description" header="Description"></Column>
              <Column field="example" header="Example"></Column>
            </DataTable>
          </div>
          <div
            v-if="api.path?.length"
            class="p-3 surface-100 border-1 border-300 border-round flex flex-column gap-2"
          >
            <div class="text-lg font-bold">Path</div>
            <DataTable :value="api.path" class="p-datatable-sm">
              <Column field="name" header="Name"></Column>
              <Column field="description" header="Description"></Column>
              <Column field="example" header="Example"></Column>
            </DataTable>
          </div>
          <div
            v-if="api.data?.length"
            class="p-3 surface-100 border-1 border-300 border-round flex flex-column gap-2"
          >
            <div class="text-lg font-bold">Data</div>
            <DataTable :value="api.data" class="p-datatable-sm">
              <Column field="name" header="Name"></Column>
              <Column field="description" header="Description"></Column>
              <Column field="example" header="Example"></Column>
            </DataTable>
          </div>
          <div
            v-if="api.responses?.length"
            class="p-3 surface-100 border-1 border-300 border-round flex flex-column gap-2"
          >
            <div class="text-lg font-bold">Response</div>
            <DataTable
              :value="api.responses"
              v-model:expandedRows="expandedRows"
              dataKey="code"
              class="p-datatable-sm"
            >
              <Column field="code" header="Code"
                ><template #body="slotProps"
                  ><code class="font-bold">
                    {{ slotProps.data.code }}
                  </code></template
                ></Column
              >
              <Column field="description" header="Description"></Column>
              <Column field="example" header="Example">
                <template #body="slotProps">
                  <Button
                    v-if="slotProps.data.example?.length"
                    label="View example response"
                    plain
                    size="small"
                    class="px-0"
                    link
                    @click="
                      expandedRows = expandedRows.some(
                        (item) => item.code === slotProps.data.code
                      )
                        ? []
                        : api.responses.filter(
                            (item) => item.code == slotProps.data.code
                          )
                    " /></template
              ></Column>
              <template #expansion="slotProps">
                <VCodeBlock
                  :code="slotProps.data.example"
                  highlightjs
                  label="Example"
                  lang="json"
                  theme="neon-bunny"
                />
              </template>
            </DataTable>
          </div>
        </div>
      </template>
    </AccordionTab>
  </Accordion>
</template>
<script setup>
import axios from "axios";
import { onBeforeMount, ref } from "vue";
import { API_BASE_URL } from "../../consts";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Tag from "primevue/tag";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import VCodeBlock from "@wdns/vue-code-block";

const props = defineProps({
  apiName: String,
});

const docData = ref([]);
const expandedRows = ref([]);
const colorSchemes = {
  get: "success",
  post: "info",
  put: "warning",
  delete: "danger",
};

const handleExpand = (e) => {
  expandedRows.value = docData.value.responses.filter((item) => item.code == e);
};

const getDocData = async () => {
  try {
    const result = await axios.get(`${API_BASE_URL}/doc/${props.apiName}`);
    docData.value = result.data.data;
  } catch (error) {
    console.log(error);
  }
};

onBeforeMount(() => {
  getDocData();
});
</script>
