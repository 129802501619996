<template>
  <v-card class="top-device-list elevation-2 rounded-lg">
    <v-card-title class="d-flex justify-space-between align-center pa-4">
      <span class="text-h6 font-weight-medium">{{ type }} by Device</span>
    </v-card-title>
    <v-card-text>
      <div
        v-if="loading"
        class="d-flex justify-center align-center"
        style="height: 400px"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <pie-chart
          :data="chartData"
          :dataset="{
            cutout: '70%',
            borderWidth: 0,
            radius: '90%',
          }"
          height="400px"
          :colors="['#4CAF50', '#2196F3', '#FFC107', '#FF5722', '#9C27B0']"
          :donut="true"
          legend="bottom"
          suffix="%"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    type: String,
    data: Array,
  },
  computed: {
    total() {
      return this.data.reduce((sum, item) => sum + Number(item.count), 0);
    },
    chartData() {
      return this.data.map((item) => [
        item.device_type.charAt(0).toUpperCase() + item.device_type.slice(1),
        (Number(item.count) / this.total) * 100,
      ]);
    },
  },
};
</script>

<style scoped>
.top-device-list {
  background: var(--overview-card-bg);
}

.v-card-title {
  background: var(--overview-card-bg);
  color: var(--text-color);
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
