<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Playlist Management</h2>

    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button
            label="Create Playlist"
            icon="pi pi-plus"
            @click="router.push({ name: 'PlaylistCreate' })"
          />
        </div>
      </template>
      <template #content>
        <!-- List of Playlists -->
        <DataTable
          :value="playlists"
          v-model:expandedRows="expandedRows.value"
          dataKey="id"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
        >
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="name" header="Playlist Name"></Column>
          <Column field="status" header="Status"></Column>
          <Column field="created_at" header="Creation Date"></Column>
          <Column field="updated_at" header="Last Updated"></Column>
          <Column header="Actions">
            <template #body="{ data }">
              <Button
                icon="pi pi-trash"
                outlined
                rounded
                severity="danger"
                @click="deletePlaylist(data)"
              />
            </template>
          </Column>
          <template #expansion="{ data }">
            <div class="ml-3">
              <Button
                plain
                text
                :label="`Podcasts in ${data.name}`"
                class="text-xl pointer-events-none"
              />
              <div class="flex flex-column align-items-start">
                <a
                  v-for="podcast in data.rss_feeds_list"
                  :key="podcast.url"
                  :href="podcast.url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button :label="podcast.name" link />
                </a>
              </div>
            </div>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Card from "primevue/card";
import { API_BASE_URL } from "@/consts";
import { useRouter } from "vue-router";

let playlists = ref([]);
let expandedRows = ref({});
onMounted(async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_BASE_URL}/playlists`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      playlists.value = response.data.playlists.map((item) => {
        item.rss_feeds_list = JSON.parse(item.rss_feeds_list);
        return item;
      });
    } else {
      console.error("Failed to fetch playlists:", response.data.message);
    }
  } catch (error) {
    console.error("Error fetching playlists:", error);
  }
});

const router = useRouter();

function onRowToggle(event) {
  expandedRows.value = event.data;
}

async function editPlaylist(playlist) {
  // Here, we'll navigate to the "create" page with the playlist's ID.
  // Assuming the route for the "create" page is named 'CreatePlaylist'
  router.push({ name: "PlaylistCreate", params: { id: playlist.id } });
}

async function deletePlaylist(playlist) {
  if (
    window.confirm(
      `Are you sure you want to delete playlist "${playlist.name}"?`
    )
  ) {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/playlists/${playlist.id}`
      );
      if (response.data.success) {
        // Remove the playlist from the local list
        playlists.value = playlists.value.filter((p) => p.id !== playlist.id);
      } else {
        console.error("Failed to delete playlist:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting playlist:", error);
    }
  }
}
</script>
