<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Seller Groups</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button
            v-if="!isAdminLoggedIn"
            @click="goToAddGroup()"
            class="justify-content-center"
          >
            Add New Group
          </Button>
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            v-model="groupTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="groups"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="groupTablesFilters"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="email" header="Email" :sortable="true"></Column>
          <Column
            field="display_id"
            header="Display ID"
            :sortable="true"
          ></Column>
          <Column>
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-center">
                <Button
                  icon="pi pi-pencil"
                  outlined
                  rounded
                  @click="goToAddGroup(slotProps.data.id)"
                />
                <!-- <Button
                  icon="pi pi-chart-line"
                  outlined
                  rounded
                  severity="help"
                  @click="goToGroupDashboard(slotProps.data.id)"
                /> -->
                <Button
                  icon="pi pi-trash"
                  outlined
                  rounded
                  severity="danger"
                  @click="ConfirmGroupDelete(slotProps.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { useRouter } from "vue-router";
import Button from "primevue/button";
import Card from "primevue/card";
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    InputText,
    Button,
    Card,
  },
  setup() {
    const groups = ref([]);
    const store = useStore();
    const router = useRouter();
    const confirm = useConfirm();
    const groupTablesFilters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const isAdminLoggedIn = store.getters.isAdminLoggedIn;

    const selectedGroup = ref({});
    const token =
      localStorage.getItem("adminToken") || localStorage.getItem("token");

    const goToGroupDashboard = (groupId) => {
      router.push({
        path: "/group-dashboard/" + groupId,
      });
    };

    const goToAddGroup = (groupId) => {
      if (groupId) {
        router.push({
          name: isAdminLoggedIn
            ? "AdminUpdateSellerGroup"
            : "UpdateSellerGroup",
          params: {
            groupId,
          },
        });
      } else {
        router.push({
          name: "AddSellerGroup",
        });
      }
    };

    const fetchGroups = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(`${API_BASE_URL}/seller-groups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data?.sellerGroups) {
          groups.value = response.data.sellerGroups;
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const deleteGroup = async (groupId) => {
      try {
        store.commit("setLoading", true);
        const response = await axios.delete(
          `${API_BASE_URL}/seller-group/${groupId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          // Remove the group from the local state
          groups.value = groups.value.filter((group) => group.id !== groupId);
        }
      } catch (error) {
        console.error("Error deleting group:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const ConfirmGroupDelete = (groupId) => {
      confirm.require({
        message: "Do you want to delete this group?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          deleteGroup(groupId);
        },
      });
    };

    onMounted(fetchGroups);

    return {
      groups,
      deleteGroup,
      goToAddGroup,
      selectedGroup,
      groupTablesFilters,
      fetchGroups,
      goToGroupDashboard,
      ConfirmGroupDelete,
      isAdminLoggedIn,
    };
  },
};
</script>
