import { createStore } from "vuex";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { formatDate } from "@/helpers";
import moment from "moment";

export default createStore({
  state: {
    isLoggedIn: false,
    partnerId: null,
    isLoading: false,
    loadingRequest: 0,
    partner: {},
    seller: {},
    admin: {},
    groupAdmin: {},
    podcaster: {},
    podcastAdmin: {},
    isSellerLoggedIn: false,
    sellerId: null,
    sellerPartnerId: null,
    podcasterId: null,
    isAdminLoggedIn: false,
    isGroupAdminLoggedIn: false,
    isPodcasterLoggedIn: false,
    isPodcastAdminLoggedIn: false,
    shows: [],
    podcasters: [],
    episodes: [],
    campaigns: [],
    alerts: [],
    sellers: [],
  },
  mutations: {
    setSellerLoggedIn(state, value) {
      state.isSellerLoggedIn = value;
    },
    setSellerId(state, value) {
      state.sellerId = value;
    },
    setSellerPartnerId(state, value) {
      state.sellerPartnerId = value;
    },
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setGroupAdminLoggedIn(state, value) {
      state.isGroupAdminLoggedIn = value;
    },
    setPartnerId(state, value) {
      state.partnerId = value;
    },
    setPartner(state, value) {
      localStorage.setItem("partner", JSON.stringify(value));
      state.partner = value;
    },
    setSeller(state, value) {
      localStorage.setItem("seller", JSON.stringify(value));
      state.seller = value;
    },
    setGroupAdmin(state, value) {
      localStorage.setItem("groupAdmin", JSON.stringify(value));
      state.groupAdmin = value;
    },
    setPodcaster(state, value) {
      localStorage.setItem("podcaster", JSON.stringify(value));
      state.podcaster = value;
    },
    setPodcastAdmin(state, value) {
      localStorage.setItem("podcastAdmin", JSON.stringify(value));
      state.podcastAdmin = value;
    },
    setPodcasterLoggedIn(state, value) {
      state.isPodcasterLoggedIn = value;
    },
    setPodcastAdminLoggedIn(state, value) {
      state.isPodcastAdminLoggedIn = value;
    },
    setLoading(state, isLoading) {
      // console.log(
      //   "isLoading: ",
      //   isLoading,
      //   ", loadingRequest: ",
      //   state.loadingRequest
      // );
      if (isLoading == true) {
        state.loadingRequest++;
        state.isLoading = isLoading;
      } else {
        state.loadingRequest--;
        if (state.loadingRequest == 0) {
          state.isLoading = false;
        }
      }
    },
    setAdminLoggedIn(state, value) {
      state.isAdminLoggedIn = value;
    },
    setAdmin(state, value) {
      localStorage.setItem("admin", JSON.stringify(value));
      state.admin = value;
    },
    setShows(state, value) {
      state.shows = value;
    },
    setPodcasters(state, value) {
      state.podcasters = value;
    },
    setEpisodes(state, value) {
      state.episodes = value;
    },
    setCampaigns(state, value) {
      state.campaigns = value;
    },
    setAlerts(state, value) {
      state.alerts = value;
    },
    setSellers(state, value) {
      state.sellers = value;
    },
  },
  getters: {
    partner(state) {
      return state.partner;
    },
    seller(state) {
      return state.seller;
    },
    groupAdmin(state) {
      return state.groupAdmin;
    },
    podcaster(state) {
      return state.podcaster;
    },
    podcastAdmin(state) {
      return state.podcastAdmin;
    },
    isLoading(state) {
      return state.isLoading;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isSellerLoggedIn(state) {
      return state.isSellerLoggedIn;
    },
    isGroupAdminLoggedIn(state) {
      return state.isGroupAdminLoggedIn;
    },
    isPodcasterLoggedIn(state) {
      return state.isPodcasterLoggedIn;
    },
    isPodcastAdminLoggedIn(state) {
      return state.isPodcastAdminLoggedIn;
    },
    sellerId(state) {
      return state.sellerId;
    },
    sellerPartnerId(state) {
      return state.sellerPartnerId;
    },
    podcasterId(state) {
      return state.podcasterId;
    },
    isAdminLoggedIn(state) {
      return state.isAdminLoggedIn;
    },
    podcasters(state) {
      return state.podcasters;
    },
    shows(state) {
      return state.shows;
    },
    episodes(state) {
      return state.episodes;
    },
    campaigns(state) {
      return state.campaigns;
    },
    alerts(state) {
      return state.alerts;
    },
    sellers(state) {
      return state.sellers;
    },
  },
  actions: {
    async loadShows({ commit }, { token }) {
      try {
        commit("setLoading", true);
        const { data } = await axios.get(
          `${API_BASE_URL}/podcaster/shows/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (data) {
          commit(
            "setShows",
            data.podcastShows
              .map((item) => ({
                ...item,
                last_fetched: item.last_fetched
                  ? moment(item.last_fetched)
                      .utc()
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "",
              }))
              .sort((a, b) =>
                a.show_title?.toLowerCase() > b.show_title?.toLowerCase()
                  ? 1
                  : -1
              )
          );
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        commit("setLoading", false);
      }
    },
    async loadPodcasters({ commit }, { token }) {
      try {
        commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/admin/podcasters", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          commit(
            "setPodcasters",
            response.data
              .map((item) => ({
                ...item,
                created_at: moment(item.created_at)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss"),
              }))
              .sort((a, b) => {
                if (a.created_at == "Invalid date" && b.created_at != "Invalid date") return -1;
                if (a.created_at != "Invalid date" && b.created_at == "Invalid date") return 1;
                return a.created_at > b.created_at ? 1 : -1;
              })
          );
        }
      } catch (error) {
        console.error("Error fetching podcasters:", error);
      } finally {
        commit("setLoading", false);
      }
    },
    async loadEpisodes({ commit }, { token }) {
      try {
        commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/podcaster/episodes", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data?.episodes) {
          commit("setEpisodes", response.data.episodes);
        }
      } catch (error) {
        console.error("Error fetching episodes:", error);
      } finally {
        commit("setLoading", false);
      }
    },
    async loadCampaigns({ commit }, { token }) {
      try {
        commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/campaigns", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          commit(
            "setCampaigns",
            response.data.map((item) => ({
              ...item,
              ctr: item.ctr.toFixed(2),
              start_date: moment(item.start_date).utc().format("YYYY-MM-DD"),
              end_date: moment(item.end_date).utc().format("YYYY-MM-DD")
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      } finally {
        commit("setLoading", false);
      }
    },
    async loadAlerts({ commit }, { token }) {
      try {
        commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/alerts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          commit(
            "setAlerts",
            response.data
              .map((item) => ({
                ...item,
                timestamp: moment(item.timestamp)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss"),
              }))
              .sort((a, b) =>
                a.timestamp < b.timestamp ? 1 : -1
              )
          );
        }
      } catch (error) {
        console.error("Error fetching alerts:", error);
      } finally {
        commit("setLoading", false);
      }
    },
    async loadSellers({ commit }, { token }) {
      try {
        commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/member-list/seller", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          commit(
            "setSellers",
            response.data.sellers.map((item) => {
              return {
                ...item,
                value: `${item.partner_id}_${item.id}`,
              };
            })
          );
        }
      } catch (error) {
        console.error("Error fetching sellers:", error);
      } finally {
        commit("setLoading", false);
      }
    },
  },
  modules: {},
});
