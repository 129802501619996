<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Partners Management</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button class="justify-content-center" @click="goToCreateNewPartner">
            Add New Partner
          </Button>
          <br /><br />
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            v-model="partnersTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="partners"
          :paginator="true"
          :rows="10"
          :filters="partnersTablesFilters"
          class="p-datatable-sm"
          :striped-rows="true"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="email" header="Email" :sortable="true"></Column>
          <Column field="domain" header="Domain" :sortable="true"></Column>
          <Column
            field="contract_type"
            header="Contract Type"
            :sortable="true"
          ></Column>
          <Column
            field="contract_value"
            header="Contract Value"
            :sortable="true"
          >
            <template #body="slotProps">
              {{
                slotProps.data.contract_value *
                  (slotProps.data.contract_type === "cpm" ? 1 : 100) +
                (slotProps.data.contract_type === "cpm" ? "" : "%")
              }}
            </template>
          </Column>
          <Column
            field="fix_imp_cpm"
            header="Fixed Imp CPM"
            :sortable="true"
          ></Column>

          <Column header="Edit">
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-center">
                <Button
                  icon="pi pi-chart-line"
                  outlined
                  rounded
                  severity="help"
                  @click="goToPartnerDashboard(slotProps.data.id)"
                />
                <Button
                  icon="pi pi-pencil"
                  outlined
                  rounded
                  @click="goToUpdatePartner(slotProps.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Card from "primevue/card";

export default {
  components: { Button, DataTable, InputText, Card },
  setup() {
    const partners = ref([]);
    const store = useStore();
    const router = useRouter();
    const partnersTablesFilters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const goToCreateNewPartner = () => {
      router.push({ name: "AddPartner" });
    };
    const goToUpdatePartner = (partnerId) => {
      router.push({
        name: "UpdatePartner",
        params: { partnerId: partnerId },
      });
    };
    const goToPartnerDashboard = (partnerId) => {
      const dbPath = "/admin/partner-dashboard/";
      router.push({
        path: dbPath + partnerId,
      });
    };
    const fetchPartners = async () => {
      try {
        store.commit("setLoading", true);
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(API_BASE_URL + "/partners", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          partners.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching partners:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };
    onMounted(fetchPartners);
    return {
      partners,
      goToCreateNewPartner,
      goToUpdatePartner,
      partnersTablesFilters,
      goToPartnerDashboard,
    };
  },
};
</script>
