<template>
  <div class="p-2">
    <div class="d-flex justify-content-between mb-4">
      <Dropdown
        v-model="showId"
        :options="showsList"
        optionValue="show_id"
        optionLabel="show_title"
        checkmark
        placeholder="All Shows"
      />
      <Calendar v-model="dates" selectionMode="range" :manualInput="false" />
    </div>
    <v-row>
      <v-col
        lg="3"
        md="4"
        sm="6"
        cols="12"
        v-for="(overview, idx) in overviews"
        :key="idx"
      >
        <overview-card
          :loading="loadingOverviews"
          :isActive="overview.type === eventType"
          :title="overview.title"
          :total="overview.total"
          :days="days"
          @click="handleActiveOverviewCard(overview.type, overview.clickable)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" cols="12">
        <view-statistics-card
          :loading="loadingOvertimeData"
          :type="type"
          :data="overtimeData"
        />
      </v-col>
      <!-- <v-col md="4" sm="6" cols="12">
        <top-country-list-card
          :loading="loadingTopByCountries"
          :type="type"
          :data="topByCountries"
        />
      </v-col> -->
    </v-row>
    <v-row>
      <v-col sm="6" cols="12">
        <top-country-list-card
          :loading="loadingTopByCountries"
          :type="type"
          :data="topByCountries"
        />
        <!-- <top-domain-list-card
          :loading="loadingTopByDomains"
          :type="type"
          :data="topByDomains"
        /> -->
      </v-col>
      <v-col sm="6" cols="12">
        <top-device-chart-card
          :loading="loadingTopByDevices"
          :type="type"
          :data="topByDevices"
        />
      </v-col>
      <v-col cols="12">
        <show-datatable-card
          :loading="loadingShowsData"
          :showId="showId"
          :data="showsData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import axios from "axios";
import moment from "moment";
import { API_BASE_URL } from "@/consts";
import { useStore } from "vuex";
import { ref, watch, computed, onBeforeMount } from "vue";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import OverviewCard from "@/components/podcaster-dashboard/OverviewCard";
import ViewStatisticsCard from "./podcaster-dashboard/ViewStatisticsCard";
import TopCountryListCard from "./podcaster-dashboard/TopCountryListCard";
import TopDomainListCard from "./podcaster-dashboard/TopDomainListCard";
import TopDeviceChartCard from "./podcaster-dashboard/TopDeviceChartCard";
import ShowDatatableCard from "./podcaster-dashboard/ShowDatatableCard";

const store = useStore();
const token = localStorage.getItem("podcasterToken");
const podcasterId = store.getters.podcaster.podcaster_id;
const showId = ref("all");
const dates = ref([
  new Date(moment().subtract(1, "years")),
  new Date(moment()),
]);
const eventType = ref("episode_imp");
const showsList = ref([]);
const loadingOverviews = ref(false);
const overviews = ref([]);
const loadingOvertimeData = ref(false);
const overtimeData = ref([]);
const loadingTopByCountries = ref(false);
const topByCountries = ref([]);
const loadingTopByDomains = ref(false);
const topByDomains = ref([]);
const loadingTopByDevices = ref(false);
const topByDevices = ref([]);
const loadingShowsData = ref(false);
const showsData = ref([]);

const type = computed(() => {
  if (eventType.value === "episode_imp") {
    return "Views";
  } else if (eventType.value === "episode_play") {
    return "Sessions";
  }
});

const handleActiveOverviewCard = (type, status) => {
  if (status) {
    eventType.value = type;
  }
};

const days = computed(() => {
  if (dates.value && dates.value[0] !== null && dates.value[1] !== null) {
    const a = moment(dates.value[1]);
    const b = moment(dates.value[0]);
    return a.diff(b, "days");
  } else {
    return 0;
  }
});

const fetchShowsList = async () => {
  try {
    store.commit("setLoading", true);
    const { data } = await axios.get(
      `${API_BASE_URL}/podcast_events/shows-list`,
      {
        params: {
          podcasterId: podcasterId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      showsList.value = [{ show_id: "all", show_title: "All Shows" }].concat(
        data.shows_list
      );
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setTimeout(() => {
      store.commit("setLoading", false);
    }, 500);
  }
};

const fetchOverviews = async () => {
  try {
    loadingOverviews.value = true;
    const { data } = await axios.get(
      API_BASE_URL + "/podcast_events/totals",
      {
        params: {
          podcasterId: podcasterId,
          showId: showId.value,
          startDate: moment(dates.value[0]).format("YYYY-MM-DD"),
          endDate: moment(dates.value[1]).format("YYYY-MM-DD"),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      overviews.value = [
        {
          title: "Views",
          total: data.total_views,
          type: "episode_imp",
          clickable: true,
        },
        {
          title: "Sessions",
          total: data.total_plays,
          type: "episode_play",
          clickable: true,
        },
        {
          title: "Unique Listeners",
          total: data.total_unique_listeners,
          type: "",
          clickable: false,
        },
        {
          title: "CTR",
          total: data.overall_ctr
            ? `${(data.overall_ctr * 100).toFixed(2)}%`
            : `0%`,
          type: "",
          clickable: false,
        },
      ];
    }
  } catch (error) {
    console.error("Error fetching shows:", error);
  } finally {
    setTimeout(() => {
      loadingOverviews.value = false;
    }, 300);
  }
};

const fetchOvertime = async () => {
  try {
    loadingOvertimeData.value = true;
    const { data } = await axios.get(
      API_BASE_URL + "/podcast_events/over-time",
      {
        params: {
          podcasterId: podcasterId,
          showId: showId.value,
          startDate: moment(dates.value[0]).format("YYYY-MM-DD"),
          endDate: moment(dates.value[1]).format("YYYY-MM-DD"),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      overtimeData.value = data.over_time_data;
    }
  } catch (error) {
    console.error("Error fetching shows:", error);
  } finally {
    setTimeout(() => {
      loadingOvertimeData.value = false;
    }, 300);
  }
};

const fetchTopCountries = async () => {
  try {
    loadingTopByCountries.value = true;
    const { data } = await axios.get(
      `${API_BASE_URL}/podcast_events/top-by-countries`,
      {
        params: {
          podcasterId: podcasterId,
          showId: showId.value,
          startDate: moment(dates.value[0]).format("YYYY-MM-DD"),
          endDate: moment(dates.value[1]).format("YYYY-MM-DD"),
          eventTypes: eventType.value,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      topByCountries.value = data.top_by_countries;
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setTimeout(() => {
      loadingTopByCountries.value = false;
    }, 300);
  }
};

const fetchTopDomains = async () => {
  try {
    loadingTopByDomains.value = true;
    const { data } = await axios.get(
      `${API_BASE_URL}/podcast_events/top-by-domain`,
      {
        params: {
          podcasterId: podcasterId,
          showId: showId.value,
          startDate: moment(dates.value[0]).format("YYYY-MM-DD"),
          endDate: moment(dates.value[1]).format("YYYY-MM-DD"),
          eventTypes: eventType.value,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      topByDomains.value = data.top_by_domain;
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setTimeout(() => {
      loadingTopByDomains.value = false;
    }, 300);
  }
};

const fetchTopDevices = async () => {
  try {
    loadingTopByDevices.value = true;
    const { data } = await axios.get(
      `${API_BASE_URL}/podcast_events/by-device-type`,
      {
        params: {
          podcasterId: podcasterId,
          showId: showId.value,
          startDate: moment(dates.value[0]).format("YYYY-MM-DD"),
          endDate: moment(dates.value[1]).format("YYYY-MM-DD"),
          eventTypes: eventType.value,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      topByDevices.value = data.by_device_type;
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setTimeout(() => {
      loadingTopByDevices.value = false;
    }, 300);
  }
};

const fetchShowsData = async () => {
  try {
    loadingShowsData.value = true;
    if (showId.value === "all") {
      const { data } = await axios.get(
        `${API_BASE_URL}/podcast_events/shows-data`,
        {
          params: {
            podcasterId: podcasterId,
            startDate: moment(dates.value[0]).format("YYYY-MM-DD"),
            endDate: moment(dates.value[1]).format("YYYY-MM-DD"),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data) {
        showsData.value = data.shows_data;
      }
    } else {
      const { data } = await axios.get(
        `${API_BASE_URL}/podcast_events/episode-data`,
        {
          params: {
            podcasterId: podcasterId,
            showId: showId.value,
            startDate: moment(dates.value[0]).format("YYYY-MM-DD"),
            endDate: moment(dates.value[1]).format("YYYY-MM-DD"),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data) {
        showsData.value = data.episode_data;
      }
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setTimeout(() => {
      loadingShowsData.value = false;
    }, 300);
  }
};

watch([showId, dates, eventType], () => {
  if (dates.value && dates.value[0] !== null && dates.value[1] !== null) {
    fetchOverviews();
    fetchOvertime();
    fetchTopCountries();
    fetchTopDomains();
    fetchTopDevices();
    fetchShowsData();
  }
});

onBeforeMount(() => {
  fetchShowsList();
  fetchOverviews();
  fetchOvertime();
  fetchTopCountries();
  fetchTopDomains();
  fetchTopDevices();
  fetchShowsData();
});
</script>
