<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      Configuration Management
    </h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <div class="flex flex-column gap-1 flex-1">
            <label for="seller-select">Select Seller:</label>
            <Dropdown
              v-model="selectedSeller"
              filter
              resetFilterOnHide
              :options="sellerOptions"
              :option-label="optionLabel"
              :loading="!sellerOptions.length"
              option-value="id"
              placeholder="Default"
              id="seller-select"
              @change="subseller = ''"
            />
          </div>

          <div class="flex flex-column gap-1 flex-1">
            <label for="subseller-select">Subseller (optional):</label>
            <Dropdown
              v-model="subseller"
              filter
              resetFilterOnHide
              :options="activeSubSellers"
              :disabled="!selectedSeller"
              :loading="!subSellerOptions.length"
              :option-label="optionLabel"
              option-value="id"
              :placeholder="
                selectedSeller ? 'Default' : 'Please select a seller'
              "
              id="subseller-select"
            />
          </div>
        </div>
      </template>
      <template #content>
        <SellerConfigComponent
          :sellerId="selectedSeller"
          :subsellerId="subseller"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import SellerConfigComponent from "./Config.vue";
import { API_BASE_URL } from "@/consts";
import { computed } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    Dropdown,
    InputText,
    SellerConfigComponent,
    Card,
  },
  props: {
    sellerid: String,
    partnerid: String,
  },
  setup(props) {
    const defaultOption = ref([{ id: null, name: "Default" }]);
    const sellerOptions = ref([]);
    const subSellerOptions = ref([]);
    const selectedSeller = ref("");
    const subseller = ref("");

    if (props.sellerid && props.partnerid) {
      selectedSeller.value = props.partnerid;
      subseller.value = props.sellerid;
    }

    const activeSubSellers = computed(() =>
      defaultOption.value.concat(
        subSellerOptions.value.filter(
          (item) => item.partner_id == selectedSeller.value
        )
      )
    );
    const optionLabel = (record) =>
      `${record.id ? `[${record.id}]` : ""} ${record.name}`;

    const fetchPartners = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const { data } = await axios.get(
          API_BASE_URL + "/member-list/partner",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (data.success) {
          const { partners } = data;
          sellerOptions.value = defaultOption.value.concat(partners);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchSellers = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const { data } = await axios.get(API_BASE_URL + "/sellers/by/partner", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data.success) {
          const { sellers } = data;
          subSellerOptions.value = sellers;
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(fetchPartners);
    onMounted(fetchSellers);

    return {
      sellerOptions,
      subSellerOptions,
      selectedSeller,
      subseller,
      activeSubSellers,
      optionLabel,
    };
  },
};
</script>
