<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      class="surface-0 border-none shadow-1"
    >
      <div class="p-3 h-full">
        <div class="h-full relative">
          <Sidebar />
          <Button
            icon="pi pi-bars"
            @click="drawer = !drawer"
            class="absolute top-0 left-0"
            rounded
            text
          />
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar class="px-2 sm:px-3 md:px-4 shadow-1 surface-0" flat>
      <div class="w-full flex justify-content-between align-items-center">
        <div>
          <Button
            v-if="!drawer"
            icon="pi pi-bars"
            @click="drawer = !drawer"
            rounded
            text
          />
        </div>
        <router-link :to="{ name: 'Dashboard' }">
          <div class="w-2rem flex align-items-center" style="width: 21px">
            <JamxLogo logo-color="var(--text-color)" />
          </div>
        </router-link>
        <div class="flex align-items-center gap-2">
          <Button
            :icon="`pi ${themeMode == 'dark' ? 'pi-sun' : 'pi-moon'}`"
            @click="toggleTheme"
            rounded
            class="w-2rem h-2rem p-0"
            size="small"
            aria-label="Theme"
          />
          <Button
            icon="pi pi-user"
            severity="secondary"
            @click="toggle"
            rounded
            aria-label="User"
          />
          <Menu
            ref="menu"
            id="overlay_menu"
            :model="items"
            :popup="true"
            style="z-index: 1012 !important"
          />
        </div>
      </div>
    </v-app-bar>

    <v-main class="flex justify-content-center flex-wrap surface-50">
      <div class="w-full py-3 px-2 sm:p-3 md:p-4">
        <router-view />
      </div>
      <Footer />
      <!--  -->
    </v-main>
  </v-app>
</template>

<script setup>
import Sidebar from "../Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import LogoImg from "@/assets/pulsar.svg";
import { ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import JamxLogo from "../icons/JamxLogo.vue";
import { usePrimeVue } from "primevue/config";
const drawer = ref(null);
const store = useStore();
const toast = useToast();
const router = useRouter();
const PrimeVue = usePrimeVue();

const menu = ref();
const themeMode = ref("dark");
const toggle = (event) => {
  menu.value.toggle(event);
};

const toggleTheme = () => {
  themeMode.value = themeMode.value == "dark" ? "light" : "dark";
  // PrimeVue.changeTheme("dark", "light", "theme-link", () => {});
  PrimeVue.changeTheme(
    `${themeMode.value == "dark" ? "light" : "dark"}`,
    `${themeMode.value}`,
    "theme-link",
    () => {}
  );
};

const getRoleByState = () => {
  if (store.getters.isSellerLoggedIn) {
    return "seller";
  } else if (store.getters.isGroupAdminLoggedIn) {
    return "group-admin";
  } else if (store.getters.isAdminLoggedIn) {
    return "admin";
  } else if (store.getters.isLoggedIn) {
    return "partner";
  }
};

const logout = () => {
  localStorage.clear();
  window.location.replace("/");
};
const items = ref([
  {
    label: "Options",
    items: [
      {
        label: "Profile",
        icon: "pi pi-user-edit",
        command: () => {
          toast.add({
            severity: "success",
            summary: "Profile",
            detail: "Will be available soon!",
            life: 5000,
          });
        },
      },
      {
        label: "Settings",
        icon: "pi pi-cog",
        command: () => {
          toast.add({
            severity: "success",
            summary: "Settings",
            detail: "Will be available soon!",
            life: 5000,
          });
        },
      },
    ],
  },
  {
    label: "Other",
    items: [
      {
        label: "Logout",
        icon: "pi pi-sign-out",
        command: logout,
      },
    ],
  },
]);
</script>
<script>
export default {
  data: () => ({ drawer: null }),
  components: { Sidebar },
};
</script>

<style scoped>
/* .app-bar-blur {
  background: rgba(var(--surface-0), 0.8) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
} */
</style>
