<template>
  <Card>
    <template #title>
      <div
        class="flex flex-column sm:flex-row sm:justify-content-between gap-3 align-items-stretch sm:align-items-center"
      >
        <div>API Key Management</div>
        <Button
          severity="info"
          icon="pi pi-book"
          outlined
          size="small"
          label="API Usage"
          @click="router.push('/developers/doc')"
        />
      </div>
    </template>
    <template #content>
      <DataTable
        :loading="requestStatus == 'fetch_pending'"
        :value="apiKeys"
        class="p-datatable-sm"
      >
        <template #empty>
          <div class="text-center text-500">You don't have any API Key</div>
        </template>
        <template #footer>
          <Button
            @click="showCreateModal = true"
            size="small"
            icon="pi pi-plus"
            label="Create API Key"
            class="w-full sm:w-auto"
          />
        </template>
        <Column field="name" header="Name"></Column>
        <Column field="api_key" header="API Key">
          <template #body="slotProps">
            <div class="w-14rem flex align-items-center">
              <div
                class="w-12rem white-space-nowrap text-overflow-ellipsis overflow-hidden"
              >
                {{ slotProps.data.api_key }}
              </div>
              <Button
                link
                rounded
                size="small"
                :icon="
                  copyBtnIcon == slotProps.data.api_key
                    ? 'pi pi-check'
                    : 'pi pi-copy'
                "
                @click="codeToClipboard(slotProps.data.api_key)"
              />
            </div>
          </template>
        </Column>
        <Column field="status" header="Status">
          <template #body="slotProps">
            <Badge
              :severity="
                slotProps.data.status == 'active' ? 'success' : 'warning'
              "
              class="capitalize border-round-3xl"
              :value="slotProps.data.status"
            />
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <div class="flex gap-2 justify-content-end">
              <Button
                size="small"
                v-if="slotProps.data.status != 'active'"
                icon="pi pi-verified"
                label="Activate"
                :loading="
                  updateRowId == slotProps.data.id &&
                  requestStatus == 'update_pending'
                "
                outlined
                @click="handleUpdateAPIKeyStatus(slotProps.data.id, true)"
                rounded
              />
              <Button
                size="small"
                v-else
                icon="pi pi-ban"
                severity="secondary"
                label="Revoke"
                :loading="
                  updateRowId == slotProps.data.id &&
                  requestStatus == 'update_pending'
                "
                outlined
                @click="handleUpdateAPIKeyStatus(slotProps.data.id, false)"
                rounded
              />
              <Button
                size="small"
                icon="pi pi-trash"
                outlined
                rounded
                severity="danger"
                :loading="
                  updateRowId == slotProps.data.id &&
                  requestStatus == 'delete_pending'
                "
                @click="handleConfirmDeleteAPIKey(slotProps.data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <CreateApiModal
    v-if="showCreateModal"
    :show-modal="showCreateModal"
    @close="handleCreateModal"
  />
</template>
<script setup>
import Card from "primevue/card";
import Button from "primevue/button";
import axios from "axios";
import { API_BASE_URL } from "../../consts";
import { onBeforeMount } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import CreateApiModal from "./modals/CreateApiModal.vue";
import { ref } from "vue";
import Badge from "primevue/badge";
import { useRouter } from "vue-router";

const router = useRouter();
const confirm = useConfirm();
const toast = useToast();
const token =
  localStorage.getItem("token") || localStorage.getItem("groupAdminToken");
const requestStatus = ref("");
const apiKeys = ref([]);
const copyBtnIcon = ref("");
const updateRowId = ref(-1);

const showCreateModal = ref(false);

const codeToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    copyBtnIcon.value = text;
    toast.add({
      severity: "success",
      summary: "Success",
      detail: `API key copied`,
      life: 4000,
    });
    setTimeout(() => (copyBtnIcon.value = ""), 1500);
  } catch (error) {
    console.error(error);
  }
};

const handleUpdateAPIKeyStatus = async (keyId, status) => {
  try {
    updateRowId.value = keyId;
    requestStatus.value = "update_pending";
    const response = await axios.put(
      API_BASE_URL + `/partner_api_key`,
      { keyId, status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      fetchData();
      toast.add({
        severity: "info",
        summary: `${status ? "activated" : "revoked"}`,
        detail: `API key has been ${status ? "activated" : "revoked"}`,
        life: 4000,
      });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Something went wrong...`,
      life: 4000,
    });
    console.log(error);
  }
};

const handleDeleteAPIKey = async (keyId) => {
  try {
    updateRowId.value = keyId;
    requestStatus.value = "delete_pending";
    const response = await axios.delete(
      API_BASE_URL + `/partner_api_key/${keyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      fetchData();
      toast.add({
        severity: "info",
        summary: "Deleted",
        detail: `API key has been deleted`,
        life: 4000,
      });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Something went wrong...`,
      life: 4000,
    });
    console.log(error);
  }
};

const handleConfirmDeleteAPIKey = (keyId) => {
  confirm.require({
    message: "Do you want to delete this API key?",
    header: "Delete Confirmation",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    accept: () => {
      handleDeleteAPIKey(keyId);
    },
  });
};

const handleCreateModal = async (e) => {
  try {
    if (!e) return;
    requestStatus.value = "create_pending";
    const response = await axios.post(
      API_BASE_URL + "/partner_api_key",
      { keyName: e },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      fetchData();
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `API key created successfully`,
        life: 4000,
      });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Something went wrong...`,
      life: 4000,
    });
    console.log(error);
  } finally {
    showCreateModal.value = false;
  }
};

const fetchData = async () => {
  try {
    requestStatus.value = "fetch_pending";
    const response = await axios.get(API_BASE_URL + "/partner_api_key", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data?.data) {
      apiKeys.value = response.data.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    requestStatus.value = "";
  }
};

onBeforeMount(() => {
  fetchData();
});
</script>
