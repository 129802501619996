<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Ads.txt Management</h2>
    <Card class="mb-3">
      <template #title>
        <div class="text-xl">Manage Ads.txt Rows</div>
      </template>
      <template #content>
        <DataTable
          :value="adsTxtRows"
          :sortMode="'multiple'"
          :multiSortMeta="multiSortMeta"
          @sort="onSort"
          class="mb-3 p-datatable-sm"
        >
          <Column
            field="row_string"
            header="Row String"
            :sortable="true"
          ></Column>
          <Column
            field="partner_name"
            header="Partner Name"
            :sortable="true"
          ></Column>
          <Column header="Actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-trash"
                class="p-button-danger p-button-sm"
                @click="deleteRow(slotProps.data.id)"
              />
            </template>
          </Column>
        </DataTable>
        <div class="flex flex-column gap-2">
          <InputText
            v-model="newRow.row_string"
            placeholder="Row String"
            class="w-full"
          />
          <AutoComplete
            v-model="newRow.partner_name"
            :suggestions="filteredPartners"
            @complete="searchPartner"
            placeholder="Partner Name"
            class="w-full"
          />
          <Button label="Add Row" @click="addRow" class="w-full" />
        </div>
      </template>
      <template #footer>
        <div class="w-full flex justify-content-end">
          <Button
            class="flex-1 sm:flex-initial"
            label="Notify Groups"
            @click="showGroupsModal = true"
          />
        </div>
      </template>
    </Card>
    <Dialog
      v-model:visible="showGroupsModal"
      header="Select Groups to Notify"
      class="w-full max-w-30rem"
      :closable="false"
      :modal="true"
    >
      <div class="flex flex-column gap-1 mb-3">
        <label for="groups_list">Select Groups:</label>
        <MultiSelect
          input-id="groups_list"
          v-model="selectedGroupsForNotify"
          :options="groupListOptions"
          option-label="name"
          placeholder="Select Groups"
          option-value="id"
          filter
          filter-placeholder="Search Groups"
        />
      </div>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          @click="notifyGroups"
          label="Send Notification"
        />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import Card from "primevue/card";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import AutoComplete from "primevue/autocomplete";
import { API_BASE_URL } from "@/consts";

const store = useStore();
const toast = useToast();
const token = localStorage.getItem("adminToken");

const adsTxtRows = ref([]);
const newRow = ref({ row_string: "", partner_name: "" });
const groupListOptions = ref([]);
const selectedGroupsForNotify = ref([]);
const showGroupsModal = ref(false);
const filteredPartners = ref([]);

const multiSortMeta = ref([]);

const onSort = (event) => {
  multiSortMeta.value = event.multiSortMeta;
};

const uniquePartners = computed(() => {
  return [...new Set(adsTxtRows.value.map((row) => row.partner_name))];
});

const searchPartner = (event) => {
  const query = event.query.toLowerCase();
  filteredPartners.value = uniquePartners.value.filter((partner) =>
    partner.toLowerCase().includes(query)
  );
};

const fetchAdsTxtRows = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(`${API_BASE_URL}/adstxt-rows`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    adsTxtRows.value = response.data.result;
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to fetch Ads.txt rows.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const addRow = async () => {
  try {
    store.commit("setLoading", true);
    await axios.post(`${API_BASE_URL}/adstxt-rows`, newRow.value, {
      headers: { Authorization: `Bearer ${token}` },
    });
    await fetchAdsTxtRows();
    newRow.value = { row_string: "", partner_name: "" };
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Row added successfully.",
      life: 4000,
    });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to add row.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const deleteRow = async (id) => {
  try {
    store.commit("setLoading", true);
    await axios.delete(`${API_BASE_URL}/adstxt-rows/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    await fetchAdsTxtRows();
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Row deleted successfully.",
      life: 4000,
    });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to delete row.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchGroupList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/member-list/group`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.data.success) {
      groupListOptions.value = response.data.groups;
    }
  } catch (error) {
    console.error(error);
  }
};

const notifyGroups = async () => {
  try {
    showGroupsModal.value = false;
    store.commit("setLoading", true);
    const response = await axios.post(
      `${API_BASE_URL}/adstxt/notify`,
      {
        groups_to_notify: selectedGroupsForNotify.value,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Successfully sent notifications.",
        life: 4000,
      });
    } else {
      throw new Error("Failed to send notifications");
    }
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Something went wrong, couldn't send notifications.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
    selectedGroupsForNotify.value = [];
  }
};

onBeforeMount(() => {
  fetchAdsTxtRows();
  fetchGroupList();
});
</script>

<style scoped>
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.25rem 0.5rem;
}

.p-datatable .p-datatable-tbody > tr.compact-row {
  height: 2.5rem;
}

.p-button.p-button-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
</style>
