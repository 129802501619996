<template>
  <Dialog
    :visible="visible"
    :modal="true"
    :closable="false"
    :header="`Generate Tag for ${selectedSeller.name}`"
    :style="{ width: '45rem', maxWidth: '92vw' }"
  >
    <div class="grid">
      <div
        v-if="partner?.can_embed_new_widget"
        class="col-12 sm:col-6 md:col-4"
      >
        <div class="mb-1">Select Widget</div>
        <Dropdown
          v-model="playerOptions.version"
          :options="widgetVersionOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select a widget"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6 md:col-4">
        <div class="mb-1">
          Player {{ playerOptions.version === 0 ? "Theme" : "Layout" }}
        </div>
        <Dropdown
          v-if="playerOptions.version === 0"
          v-model="playerOptions.theme"
          :options="themeOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select a Theme"
          class="w-full"
        />
        <Dropdown
          v-else
          v-model="playerOptions.layout"
          :options="layoutOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select a Layout"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6 md:col-4">
        <div class="mb-1">Player Width</div>
        <InputNumber
          inputId="integeronly"
          :min="300"
          :max="1200"
          placeholder="Player Width"
          v-model="playerOptions.width"
          class="w-full"
        />
      </div>
    </div>
    <TabView
      v-if="
        partner.domain &&
        partner.id &&
        (playerOptions.theme.length || playerOptions.version === 1)
      "
      :key="playerOptions.version"
    >
      <TabPanel header="Embed Snippet">
        <div>
          <div class="mb-1">
            Copy & paste this code to where you want to embed the player.
          </div>
          <div
            class="bg-primary-reverse overflow-x-hidden border-1 border-round-md p-3 mb-2 relative"
          >
            <Button
              @click="codeToClipboard('componentcode')"
              icon="pi pi-copy"
              text
              aria-label="Copy"
              v-tooltip.bottom="'Copy'"
              class="absolute right-0 top-0 embed-code-copy-btn"
            />
            <code ref="componentcode">{{ componentCode }}</code>
          </div>
          <div class="mb-1">
            And put this snippet before your body tag ends.
          </div>
          <div
            class="bg-primary-reverse overflow-x-hidden border-1 border-round-md p-3 relative"
          >
            <Button
              @click="codeToClipboard('componentsrc')"
              icon="pi pi-copy"
              text
              aria-label="Copy"
              v-tooltip.bottom="'Copy'"
              class="absolute right-0 top-0 embed-code-copy-btn"
            />
            <code ref="componentsrc">{{ componentSrc }}</code>
          </div>
        </div>
        <div class="mt-3 mb-1">ads.txt contents</div>
        <div
          class="surface-50 surface-border text-color-secondary border-1 overflow-x-hidden border-round-md p-3 mb-2 relative"
        >
          <Button
            @click="codeToClipboard('adstxt')"
            icon="pi pi-copy"
            text
            aria-label="Copy"
            security="secondary"
            v-tooltip.bottom="'Copy'"
            class="absolute right-0 top-0 embed-code-copy-btn"
          />

          <pre ref="adstxt" class="my-0">{{ adsTxtCode }}</pre>
        </div>
        <div class="my-1">
          <p class="my-0">This content should be added to:</p>
          <p class="text-blue-500 my-0">/ads.txt</p>
        </div>
      </TabPanel>
      <TabPanel v-if="playerOptions.version === 0" header="Preview">
        <iframe
          :src="iframeSrc"
          allowtransparency="true"
          frameborder="0"
          height="250"
          scrolling="no"
          :style="`width: 100%;${
            playerOptions.width > 0
              ? 'max-width: ' + playerOptions.width + 'px'
              : ''
          }`"
        ></iframe>
      </TabPanel>
    </TabView>
    <template #footer>
      <Button
        v-if="
          partner.domain &&
          partner.id &&
          (playerOptions.theme.length || playerOptions.version === 1)
        "
        label="Save as file"
        @click="saveEmbedAsFile"
      />
      <Button label="Close" class="mt-3" outlined @click="closeDialog" />
    </template>
  </Dialog>
</template>

<script>
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { adsTxtFixed } from "@/consts";
import { downloadTextFile, generateEmbedGuide } from "@/helpers";

export default {
  components: {
    Dialog,
    InputNumber,
    Dropdown,
    Button,
    TabView,
    TabPanel,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedSeller: {
      type: Object,
      default: () => ({}),
    },
    adsTxtContent: {
      type: String,
      default: "",
    },
    partner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playerOptions: {
        version: 0,
        theme: "",
        layout: this.partner?.default_widget_layout || "0",
        width: 0,
      },
      themeOptions: [
        { label: "Light Theme", value: "0" },
        { label: "Dark Theme", value: "1" },
        { label: "River Theme", value: "2" },
        { label: "Star Theme", value: "3" },
      ],
      layoutOptions: [
        { label: "Low Visible", value: "0" },
        { label: "High Visible", value: "1" },
      ],
      widgetVersionOptions: [
        { label: "Old Player", value: 0 },
        { label: "New Widget", value: 1 },
      ],
    };
  },
  computed: {
    isOldPlayer() {
      return this.playerOptions.version === 0;
    },
    componentCode() {
      const tagName = this.isOldPlayer ? "waves" : "jamx";
      const versionAttr = this.isOldPlayer ? "theme" : "layout";
      const versionValue = this.isOldPlayer
        ? this.playerOptions.theme
        : this.playerOptions.layout;
      return `<${tagName}-player seller="${this.partner.id}" subseller="${this.selectedSeller.id}" domain="${this.selectedSeller.domain}" ${versionAttr}="${versionValue}" width="${this.playerOptions.width}"></${tagName}-player>`;
    },
    componentSrc() {
      const domain = this.isOldPlayer
        ? "cdn.podcastswaves.com"
        : "wgt.podcastswaves.com";
      return `<script src="https://${domain}/jamx.player.js"><\/script>`;
    },
    adsTxtCode() {
      return `${adsTxtFixed(
        this.selectedSeller.group_id,
        this.selectedSeller.group_display_id
      )}\n${this.adsTxtContent}`;
    },
    iframeSrc() {
      return `https://cdn.podcastswaves.com?theme=${this.playerOptions.theme}&seller=5000&subseller=1183&width=${this.playerOptions.width}`;
    },
    iframeStyle() {
      return `width: 100%;${
        this.playerOptions.width > 0
          ? "max-width: " + this.playerOptions.width + "px"
          : ""
      }`;
    },
    showTabs() {
      return (
        this.partner.domain &&
        this.partner.id &&
        (this.playerOptions.theme.length || this.playerOptions.version === 1)
      );
    },
    showSaveButton() {
      return (
        this.partner.domain &&
        this.partner.id &&
        this.playerOptions.theme.length
      );
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    codeToClipboard(refName) {
      let clipboardData = this.$refs[refName].innerText;
      navigator.clipboard.writeText(clipboardData);
    },
    saveEmbedAsFile() {
      let embedData = generateEmbedGuide(
        this.componentCode,
        this.componentSrc,
        this.adsTxtCode
      );
      downloadTextFile(embedData, "how_to_embed.txt");
    },
  },
};
</script>

<style>
.embed-mode-select > * {
  width: 50%;
}

.embed-code-copy-btn:focus {
  box-shadow: none !important;
}
</style>
