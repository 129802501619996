<template>
  <Card>
    <template #title>
      <div
        class="flex flex-column sm:flex-row sm:justify-content-between gap-3 align-items-stretch sm:align-items-center"
      >
        <div>API Document</div>
        <Button
          severity="info"
          icon="pi pi-chevron-left"
          outlined
          size="small"
          label="Back to API"
          @click="router.back()"
        />
      </div>
    </template>
    <template #content>
      <div class="pb-5">
        This is the list of endpoints that return different types report data.
      </div>
      <ApiDoc apiName="report" />
    </template>
  </Card>
</template>

<script setup>
import Card from "primevue/card";
import Button from "primevue/button";
import ApiDoc from "../ApiDoc/ApiDoc.vue";
import { useRouter } from "vue-router";

const router = useRouter();
</script>
