<template>
  <div>
    <Message
      v-if="!sellerData?.adstxt_verified"
      class="select-none cursor-pointer"
      :closable="false"
      :severity="sellerData?.adstxt_last_check ? 'error' : 'warn'"
      @click="
        sellerData?.adstxt_last_check
          ? (showAdstxtVerificationResult = true)
          : null
      "
      >{{
        sellerData?.adstxt_last_check
          ? "Ads.txt validation failed. Click for more details."
          : "Ads.txt validation has not been done yet"
      }}</Message
    >
    <h2 class="mt-0" style="color: var(--text-color)">Dashboard</h2>
    <div class="mb-4 flex flex-column sm:flex-row gap-2 sm:gap-3">
      <Calendar
        v-model="dateRange"
        :showIcon="true"
        :number-of-months="2"
        :selectionMode="'range'"
        @update:modelValue="setDateRange"
      >
      </Calendar>
      <Button @click="fetchOnDateRange">Fetch Data</Button>
    </div>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Partner Daily</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            class="pb-4"
            :data="chartData"
            :options="{ responsive: true, maintainAspectRatio: false }"
          ></line-chart>
          <LoaderSm v-if="chartLoading.partner_daily" />
        </div>
        <DataTable
          :value="dailyData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.daily"
          lazy
          @page="(e) => fetchData('partner_daily', e)"
          @sort="(e) => fetchData('partner_daily', e)"
          :loading="tableLoading.partner_daily"
        >
          <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          ></Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.partnerDaily" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Partner Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="monthlyData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.monthly"
          lazy
          @page="(e) => fetchData('partner_monthly', e)"
          @sort="(e) => fetchData('partner_monthly', e)"
          :loading="tableLoading.partner_monthly"
        >
          <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          ></Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.partnerMonthly" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Geo Location</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            class="pb-4"
            :data="chartDataPerGeo"
            :options="{ responsive: true, maintainAspectRatio: false }"
            legend="{display: false}"
          ></line-chart>
          <LoaderSm v-if="chartLoading.partner_per_geo_daily" />
        </div>
        <DataTable
          :value="perGeoData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.geoly"
          lazy
          @page="(e) => fetchData('partner_per_geo_daily', e)"
          @sort="(e) => fetchData('partner_per_geo_daily', e)"
          :loading="tableLoading.partner_per_geo_daily"
        >
          <Column
            v-for="col in columnsPerGeo"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_per_geo_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.partnerPerGeoDaily"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
  <AdstxtMissingLinesModal
    :visible="showAdstxtVerificationResult"
    :missing-lines="sellerData?.adstxt_missing_lines"
    :last-checked="sellerData?.adstxt_last_check"
    @close-modal="showAdstxtVerificationResult = false"
  />
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Message from "primevue/message";
import Button from "primevue/button";

import { useStore } from "vuex";
import { formatCurrencyNumber, adjustDate } from "../helpers";
import LoaderSm from "./LoaderSm.vue";
import AdstxtMissingLinesModal from "./AdstxtValResult/AdstxtMissingLinesModal.vue";

export default {
  components: {
    Calendar,
    Card,
    Button,
    LoaderSm,
    Message,
    AdstxtMissingLinesModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const groupAdminMode = route.name == "GroupAdminSellerDashboard";
    const sellerData = store.getters.seller;
    const showAdstxtVerificationResult = ref(false);

    const columns = [
      { header: "Date", field: "date_trunc" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
    ];

    const columnsPerGeo = [
      { header: "Date", field: "date_trunc" },
      { header: "Geo", field: "geo" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
    ];

    const sellerId = router.currentRoute.value.params.sellerId;
    if (sellerId && !groupAdminMode) {
      columns.push(
        { header: "Cost", field: "cost" },
        { header: "Profit", field: "profit" }
      );
    }

    columns.push({ header: "ECPM", field: "ecpm" });

    const rows = ref(10);
    const totalRecords = ref({ daily: 0, monthly: 0, geoly: 0 });
    const first = ref({ daily: 0, monthly: 0 });

    const tableLoading = ref({
      partner_daily: false,
      partner_monthly: false,
      partner_per_geo_daily: false,
    });

    const chartLoading = ref({
      partner_daily: false,
      partner_per_geo_daily: false,
    });

    const monthlyData = ref([]);
    const dailyData = ref([]);
    const perGeoData = ref([]);

    const chartData = ref({});
    const chartDataPerGeo = ref({});
    const csvData = ref({
      partnerDaily: [],
      partnerMonthly: [],
      partnerPerGeoDaily: [],
    });

    // Get today's date
    var today = ref(new Date());
    today.value.setHours(0, 0, 0, 0); // Set time to 00:00:00

    // Get the date 30 days ago
    var thirtyDaysAgo = ref(new Date());
    thirtyDaysAgo.value.setDate(thirtyDaysAgo.value.getDate() - 7);
    thirtyDaysAgo.value.setHours(0, 0, 0, 0); // Set time to 00:00:00

    const dateRange = ref([thirtyDaysAgo.value, today.value]);

    const setDateRange = (value) => {
      dateRange.value = value;
    };

    const finishLoading = (dataMode) => {
      if (dataMode.includes("all"))
        chartLoading.value[dataMode.slice(0, -4)] = false;
      else tableLoading.value[dataMode] = false;
    };

    const fetchData = async (
      dataMode,
      { page = 0, rows = 5, sortField, sortOrder }
    ) => {
      try {
        if (dataMode.includes("all"))
          chartLoading.value[dataMode.slice(0, -4)] = true;
        else tableLoading.value[dataMode] = true;
        const sellerId = router.currentRoute.value.params.sellerId;

        let token = "";
        if (sellerId) {
          if (groupAdminMode) token = localStorage.getItem("groupAdminToken");
          else token = localStorage.getItem("token");
        } else {
          token = localStorage.getItem("sellerToken");
        }

        const startDate = adjustDate(dateRange.value?.[0]);
        const endDate = adjustDate(dateRange.value?.[1]);

        const params = {
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
          dataMode,
          page,
          rows,
          sortField,
          sortOrder,
        };

        if (sellerId) {
          params.sellerId = sellerId;
        }

        const { data } = await axios.get(API_BASE_URL + "/seller/dashboard", {
          params,
          headers: { Authorization: `Bearer ${token}` },
        });

        if (data.success) {
          if (data.data?.partnerMonthly?.length) {
            if (dataMode == "partner_monthly") {
              totalRecords.value.monthly = parseInt(
                data.data.partnerMonthly[0].count
              );
              monthlyData.value = data.data.partnerMonthly.map((item) => {
                return {
                  date_trunc: item.date_trunc,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });
            } else {
              csvData.value.partnerMonthly = data.data.partnerMonthly;
            }
          } else if (dataMode == "partner_monthly") {
            totalRecords.value.monthly = 0;
            monthlyData.value = [];
          }
          if (data.data?.partnerDaily?.length) {
            if (dataMode == "partner_daily") {
              totalRecords.value.daily = parseInt(
                data.data.partnerDaily[0].count
              );
              dailyData.value = data.data.partnerDaily.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });
            } else {
              csvData.value.partnerDaily = data.data.partnerDaily;
              const dailyChartData = data.data.partnerDaily.map((item) => {
                return [item.date_trunc, item.revenue.toFixed(2)];
              });

              chartData.value = dailyChartData;
            }
          } else if (dataMode == "partner_daily") {
            totalRecords.value.daily = 0;
            dailyData.value = [];
            chartData.value = [];
          }
          if (data.data?.partnerPerGeoDaily?.length) {
            if (dataMode == "partner_per_geo_daily") {
              totalRecords.value.geoly = parseInt(
                data.data.partnerPerGeoDaily[0].count
              );
              perGeoData.value = data.data.partnerPerGeoDaily;

              perGeoData.value = perGeoData.value.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  geo: item.geo,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });
            } else {
              csvData.value.partnerPerGeoDaily = data.data.partnerPerGeoDaily;
              const perGeoChartData = {};

              let perGeoDataChart = data.data.partnerPerGeoDaily;

              perGeoDataChart = perGeoDataChart.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  geo: item.geo,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });

              for (let i = 0; i < perGeoDataChart.length; i++) {
                const geoData = perGeoDataChart[i];

                if (!perGeoChartData[geoData.geo]) {
                  perGeoChartData[geoData.geo] = {};
                }

                perGeoChartData[geoData.geo][geoData.date_trunc] =
                  geoData.revenue;
              }

              chartDataPerGeo.value = Object.entries(perGeoChartData).map(
                ([geo, data]) => ({
                  name: geo,
                  data: Object.entries(data).reduce((acc, [date, price]) => {
                    acc[date] = parseFloat(price.replace(/[$,]/g, ""));
                    return acc;
                  }, {}),
                })
              );
            }
          } else if (dataMode == "partner_per_geo_daily") {
            totalRecords.value.geoly = 0;
            chartDataPerGeo.value = [];
          }
        }
      } catch (error) {
        // Handle errors
        console.log(error);
      } finally {
        finishLoading(dataMode);
      }
    };

    const fetchOnDateRange = async () => {
      const dataModeArr = [
        "partner_daily",
        "partner_daily_all",
        "partner_monthly",
        "partner_monthly_all",
        "partner_per_geo_daily",
        "partner_per_geo_daily_all",
      ];
      dataModeArr.map((dataMode) =>
        fetchData(dataMode, {
          page: 0,
          rows: rows.value,
          sortField: null,
          sortOrder: null,
        })
      );
    };

    onMounted(() => {
      fetchOnDateRange();
    });

    return {
      sellerData,
      showAdstxtVerificationResult,
      columns,
      columnsPerGeo,
      tableLoading,
      chartLoading,
      monthlyData,
      dailyData,
      perGeoData,
      fetchData,
      fetchOnDateRange,
      chartData,
      chartDataPerGeo,
      dateRange,
      setDateRange,
      rows,
      totalRecords,
      first,
      csvData,
    };
  },
};
</script>
