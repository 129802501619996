<template>
  <div>
    <Card>
      <template #content>
        <!-- Search Box -->
        <div class="mb-3 w-full">
          <div class="p-inputgroup flex-1">
            <InputText
              v-model="searchQuery"
              placeholder="Search for podcasts..."
            />
            <Button icon="pi pi-search" @click="searchPodcasts" />
          </div>
        </div>

        <!-- Podcasts Search Results -->
        <DataTable :value="podcasts" v-if="podcasts.length" class="mb-5">
          <Column field="image" header="Image">
            <template #body="{ data }">
              <div
                class="w-9rem h-9rem bg-gray-100 overflow-hidden bg-center bg-cover border-round-lg shadow-2"
                :style="{ backgroundImage: `url(${data.image})` }"
              ></div>
            </template>
          </Column>

          <Column field="title" header="Podcast Title">
            <template #body="{ data }">
              <div class="line-clamp-5">
                {{ data.title }}
              </div>
            </template>
          </Column>
          <Column field="description" header="Description">
            <template #body="{ data }">
              <div class="line-clamp-5">
                {{ data.description }}
              </div>
            </template>
          </Column>
          <Column field="author" header="Author">
            <template #body="{ data }">
              <div class="line-clamp-5">
                {{ data.author }}
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{ data }">
              <Button
                :icon="
                  playlist.some((p) => p.url === data.url)
                    ? 'pi pi-minus'
                    : 'pi pi-plus'
                "
                outlined
                rounded
                @click="toggleInPlaylist(data)"
                :severity="
                  playlist.some((p) => p.url === data.url) ? 'danger' : 'info'
                "
              />
            </template>
          </Column>
        </DataTable>

        <!-- User's Playlist -->
        <DataTable
          :value="playlist"
          v-if="playlist.length"
          class="mb-4 p-datatable-sm"
        >
          <Column field="title" header="Playlist Podcasts"></Column>
          <Column>
            <template #body="{ data }">
              <Button
                severity="danger"
                icon="pi pi-trash"
                outlined
                rounded
                @click="removeFromPlaylist(data)"
              />
            </template>
          </Column>
        </DataTable>
        <div class="w-full">
          <div class="p-inputgroup flex-1">
            <InputText
              v-model="playlistName"
              :class="{ 'p-invalid': submitted && !playlistName }"
              placeholder="Playlist Name"
            />
            <Button
              :label="editMode ? 'Update Playlist' : 'Create Playlist'"
              icon="pi pi-check"
              @click="submitPlaylist"
              :disabled="!playlist.length"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import axios from "axios";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { API_BASE_URL } from "@/consts";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import Card from "primevue/card";

const playlistName = ref("");
const submitted = ref(false); // To track form submission
const router = useRouter();
const toast = useToast();
const searchQuery = ref("");
const podcasts = ref([]);
const playlist = ref([]);
const route = useRoute();
const playlistId = route.params.id; // Get ID from the route
const editMode = ref(!!playlistId); // If ID exists, we're in edit mode

onMounted(async () => {
  if (editMode.value) {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${API_BASE_URL}/playlists/${playlistId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        playlist.value = response.data.playlist.rss_feeds_list;
        // You can also populate other fields if necessary
      }
    } catch (error) {
      console.error("Error fetching playlist:", error);
    }
  }
});

function toggleInPlaylist(podcast) {
  if (playlist.value.some((p) => p.url === podcast.url)) {
    removeFromPlaylist(podcast);
  } else {
    addToPlaylist(podcast);
  }
}

async function searchPodcasts() {
  try {
    const postData = {
      search: searchQuery.value,
    };
    const token = localStorage.getItem("token");

    const response = await axios.post(
      `${API_BASE_URL}/podcasts/search`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success) {
      podcasts.value = response.data.data;
    }
  } catch (error) {
    console.error("Error fetching podcasts:", error);
  }
}

function addToPlaylist(podcast) {
  if (!playlist.value.some((p) => p.url === podcast.url)) {
    playlist.value.push(podcast);
  }
}

function removeFromPlaylist(podcast) {
  const index = playlist.value.findIndex((p) => p.url === podcast.url);
  if (index !== -1) {
    playlist.value.splice(index, 1);
  }
}

async function submitPlaylist() {
  submitted.value = true;
  if (!playlistName.value) {
    return;
  }

  try {
    // Convert podcasts to the desired JSON format
    const podcastsJson = playlist.value.map((p) => ({
      name: p.title.replace(/"/g, '\\"'), // This will escape any double quotes in the title
      url: p.url,
    }));

    const token = localStorage.getItem("token");

    const response = await axios.post(
      `${API_BASE_URL}/playlists/create`,
      {
        name: playlistName.value,
        podcasts_json: podcastsJson,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      router.push("/playlists"); // Redirect to playlists page
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Playlist created successfully!",
        life: 4000,
      });
    }
  } catch (error) {
    console.error("Error submitting playlist:", error);
  }
}
</script>

<style scoped>
.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  white-space: pre-wrap;
}
</style>
