<template>
  <Dialog
    :visible="showModal"
    :modal="true"
    :closable="false"
    header="Create API Key"
  >
    <template #footer>
      <div
        class="flex justify-content-end gap-3 align-items-stretch flex-column sm:flex-row"
      >
        <Button
          @click="emit('close', null)"
          label="Cancel"
          icon="pi pi-times"
          outlined
          severity="danger"
        />
        <Button
          @click="handleSubmit"
          icon="pi pi-save"
          :loading="loading"
          label="Create"
        />
      </div>
    </template>
    <div class="flex flex-column gap-2">
      <label for="username">API Key Name</label>
      <InputText
        id="username"
        placeholder="Name"
        v-model="keyName"
        :class="{ 'p-invalid': submitted && !keyName.length }"
      />
      <small v-if="submitted && !keyName.length" class="p-error"
        >You must provide the name</small
      >
    </div>
  </Dialog>
</template>
<script setup>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { ref } from "vue";
const props = defineProps({
  showModal: Boolean,
});
const emit = defineEmits(["close"]);

const keyName = ref("");
const submitted = ref(false);
const loading = ref(false);

const handleSubmit = () => {
  submitted.value = true;
  if (!keyName.value.length) return;
  emit("close", keyName.value);
  loading.value = true;
};
</script>
