<template>
  <div class="widget-loader">
    <v-progress-circular
      indeterminate
      :size="25"
      color="#7f88a8"
      :width="2"
    ></v-progress-circular>
  </div>
</template>
<style scoped>
.widget-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
