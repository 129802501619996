<template>
  <div
    class="flex align-items-center h-full px-3 w-full sm:w-9 md:w-8 lg:w-7 xl:w-5 mx-auto"
  >
    <div v-if="showThankYou" class="p-4 w-full text-center">
      <h2 class="text-4xl font-bold mb-4">Thank You for Signing Up!</h2>
      <p class="text-xl mb-4">
        Redirecting to the login page in {{ countdown }} seconds...
      </p>
    </div>
    <div v-else class="p-4 w-full">
      <div class="text-center mb-5">
        <JamxLogo class="w-6rem h-6rem" logo-color="#d9ff3f" />
        <div
          class="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-3 text-900"
        >
          Join Jamx
        </div>
        <span class="text-900 text-xl sm:text-2xl font-medium line-height-3">
          Sign up to start monetizing your podcast
        </span>
      </div>

      <form @submit.prevent="signup">
        <label for="name" class="block text-900 font-medium mb-2">Name</label>
        <InputText
          v-model="name"
          id="name"
          placeholder="Your Name"
          required
          class="w-full mb-3 p-3 text-900"
          :class="{ 'p-invalid': v$.name.$invalid && submitted }"
        />
        <small v-if="v$.name.$invalid && submitted" class="p-error"
          >Name is required</small
        >

        <label for="email" class="block text-900 font-medium mb-2">Email</label>
        <InputText
          v-model="email"
          id="email"
          placeholder="your@email.com"
          required
          type="email"
          class="w-full mb-3 p-3 text-900"
          :class="{ 'p-invalid': v$.email.$invalid && submitted }"
        />
        <small v-if="v$.email.$invalid && submitted" class="p-error"
          >Valid email is required</small
        >

        <label for="password" class="block text-900 font-medium mb-2"
          >Password</label
        >
        <InputText
          v-model="password"
          id="password"
          placeholder="Password"
          required
          type="password"
          class="w-full mb-3 p-3 text-900"
          :class="{ 'p-invalid': v$.password.$invalid && submitted }"
        />
        <small v-if="v$.password.$invalid && submitted" class="p-error"
          >Password is required</small
        >

        <label for="rssUrl" class="block text-900 font-medium mb-2"
          >RSS URL</label
        >
        <InputText
          v-model="rssUrl"
          id="rssUrl"
          placeholder="https://example.com/feed.xml"
          required
          type="url"
          class="w-full mb-3 p-3 text-900"
          :class="{ 'p-invalid': v$.rssUrl.$invalid && submitted }"
        />
        <small v-if="v$.rssUrl.$invalid && submitted" class="p-error"
          >Valid RSS URL is required</small
        >

        <label for="avgListeners" class="block text-900 font-medium mb-2"
          >Average Downloads per Month</label
        >
        <InputNumber
          v-model="avgListenersPerMonth"
          id="avgListeners"
          placeholder="10000"
          required
          :min="0"
          class="w-full mb-3 p-3 text-900"
          :class="{
            'p-invalid': v$.avgListenersPerMonth.$invalid && submitted,
          }"
        />
        <small
          v-if="v$.avgListenersPerMonth.$invalid && submitted"
          class="p-error"
          >Average downloads is required and must be a positive number</small
        >

        <Button
          type="submit"
          label="Sign Up"
          icon="pi pi-user-plus"
          class="w-full mt-4"
          :loading="loading"
          style="background-color: #d9ff3f; border-color: #d9ff3f; color: #333"
        />
      </form>
    </div>
  </div>
  <Toast />
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email as emailValidation,
  url,
  numeric,
  minValue,
} from "@vuelidate/validators";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Toast from "primevue/toast";
import JamxLogo from "@/components/icons/JamxLogo.vue";

const router = useRouter();
const toast = useToast();

const name = ref("");
const email = ref("");
const password = ref("");
const rssUrl = ref("");
const avgListenersPerMonth = ref(null);
const loading = ref(false);
const submitted = ref(false);
const showThankYou = ref(false);
const countdown = ref(5);

const rules = computed(() => ({
  name: { required },
  email: { required, emailValidation },
  password: { required },
  rssUrl: { required, url },
  avgListenersPerMonth: { required, numeric, minValue: minValue(0) },
}));

const v$ = useVuelidate(rules, {
  name,
  email,
  password,
  rssUrl,
  avgListenersPerMonth,
});

const signup = async () => {
  submitted.value = true;
  const isFormCorrect = await v$.value.$validate();

  if (!isFormCorrect) {
    toast.add({
      severity: "error",
      summary: "Validation Error",
      detail: "Please check the form for errors",
      life: 3000,
    });
    return;
  }

  loading.value = true;
  try {
    const response = await axios.post(
      API_BASE_URL + "/podcaster_frontend/platformSignup",
      {
        name: name.value,
        email: email.value,
        password: password.value,
        rss: rssUrl.value,
        avgPerMonth: avgListenersPerMonth.value,
      }
    );

    if (response.data.success) {
      showThankYou.value = true;
      const timer = setInterval(() => {
        countdown.value--;
        if (countdown.value === 0) {
          clearInterval(timer);
          router.push("/login");
        }
      }, 1000);
    } else {
      toast.add({
        severity: "error",
        summary: "Signup Failed",
        detail: response.data.message || "An unexpected error occurred",
        life: 5000,
      });
    }
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.response) {
      switch (error.response.status) {
        case 409:
          errorMessage = "User already exists. Please try logging in.";
          break;
        case 410:
          errorMessage = "RSS feed is already exists.";
          break;
        case 400:
          errorMessage = "Invalid input. Please check your form data.";
          break;
        default:
          errorMessage = error.response.data.message || errorMessage;
      }
    }
    toast.add({
      severity: "error",
      summary: "Signup Error",
      detail: errorMessage,
      life: 5000,
    });
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.p-inputtext {
  background: #333;
  border: #333;
  box-shadow: none;
}
.p-inputtext.p-invalid {
  border-color: #ef4444;
}
.p-error {
  color: #ef4444;
}
</style>
