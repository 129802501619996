import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PrimeVue from "primevue/config";
import TreeSelect from "primevue/treeselect";
import Dialog from "primevue/dialog";
import VueChartkick from "vue-chartkick";
import "chartkick/chart.js";
import "@fortawesome/fontawesome-free/css/all.css";
import JsonCSV from "vue-json-csv";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import vuetify from "./plugins/vuetify";

const token = localStorage.getItem("token");

if (token) {
  store.commit("setLoggedIn", true);
}

const app = createApp(App);
app.use(vuetify);
app.use(PrimeVue, { ripple: true }).use(ToastService).use(ConfirmationService);

app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("TreeSelect", TreeSelect);
app.component("Dialog", Dialog);
app.component("downloadCsv", JsonCSV);
app.directive("tooltip", Tooltip);
app.use(VueChartkick);

app.use(store).use(router).mount("#app");
