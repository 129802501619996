<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Tag Groups</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button @click="openDialog(0, '')" class="justify-content-center">
            Add New Tag Group
          </Button>
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            v-model="tagGroupTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="tagGroups"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="tagGroupTablesFilters"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column>
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-center">
                <Button
                  icon="pi pi-pencil"
                  outlined
                  rounded
                  @click="openDialog(slotProps.data.id, slotProps.data.name)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
  <Dialog
    v-model:visible="openModal"
    :header="`${selectedKey > 0 ? 'Edit' : 'Add'} ${
      selectedKey > 0 ? selectedKey : 'New Tag Group'
    }`"
    :modal="true"
    :closable="false"
    class="w-10 sm:w-7 md:w-5 lg:w-4 xl:w-3"
  >
    <div class="flex flex-column gap-1 mb-3">
      <label for="name">Name:</label>
      <InputText
        id="name"
        type="text"
        v-model="selectedValue"
        :class="{ 'p-invalid': submitted && !selectedValue.length }"
      />
    </div>
    <div class="w-full flex justify-content-end gap-2">
      <Button
        class="flex-1 sm:flex-initial"
        outlined
        label="Cancel"
        @click="openModal = false"
      />
      <Button
        class="flex-1 sm:flex-initial"
        @click="saveValue"
        label="Submit"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";

const toast = useToast();
const store = useStore();

const tagGroups = ref([]);
const openModal = ref(false);
const selectedKey = ref(0);
const selectedValue = ref("");
const submitted = ref(false);
const tagGroupTablesFilters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const openDialog = (groupId, groupName) => {
  selectedKey.value = groupId;
  selectedValue.value = groupName;
  submitted.value = false;
  openModal.value = true;
};

const fetchData = async () => {
  try {
    store.commit("setLoading", true);
    const token = localStorage.getItem("adminToken");
    const response = await axios.get(API_BASE_URL + "/tag-group", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      tagGroups.value = response.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    store.commit("setLoading", false);
  }
};

const saveValue = async () => {
  submitted.value = true;
  if (!selectedValue.value.length) return;
  try {
    store.commit("setLoading", true);
    const token = localStorage.getItem("adminToken");
    const response = await axios.post(
      API_BASE_URL + "/add-tag-group",
      {
        name: selectedValue.value,
        tagGroupId: selectedKey.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data?.success) {
      submitted.value = false;

      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Tag Group ${
          selectedKey.value ? "updated" : "added"
        } successfully!`,
        life: 4000,
      });

      fetchData();
    } else {
      // Handle unsuccessful submission
      toast.add({
        severity: "error",
        summary: "Error",
        detail: `Something went wrong...`,
        life: 4000,
      });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Something went wrong...`,
      life: 4000,
    });
    console.log(error);
  } finally {
    openModal.value = false;
    store.commit("setLoading", false);
  }
};

onMounted(() => {
  fetchData();
});
</script>
