<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{ tagId ? "Update" : "Add" }} Tag
    </h2>
    <form @submit.prevent="submitTag">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Tag Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="name">Name</label>
              <InputText
                id="name"
                v-model="form.name"
                :class="{ 'p-invalid': submitted && !form.name }"
              />
              <small v-if="submitted && !form.name" class="p-error"
                >Name is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="cpm">CPM</label>
              <InputNumber
                id="cpm"
                v-model="form.cpm"
                :class="{ 'p-invalid': submitted && !form.cpm }"
              />
              <small v-if="submitted && !form.cpm" class="p-error"
                >CPM is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="url">URL</label>
              <InputGroup>
                <InputText
                  id="url"
                  v-model="form.url"
                  :class="{ 'p-invalid': submitted && !form.url }"
                />
                <Button label="Add Macro" @click="toggleMacro" />
                <OverlayPanel ref="macroPanel">
                  <Listbox
                    :options="macros"
                    class="border-none h-20rem overflow-auto"
                    @change="handleMacroAdd"
                  />
                </OverlayPanel>
              </InputGroup>
              <small v-if="submitted && !form.url" class="p-error"
                >URL is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="tag_group">Tag Group</label>
              <Dropdown
                inputId="tag_group"
                v-model="form.tag_group"
                :options="tagGroups"
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Tag Group"
                class="w-full"
              />
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>Restrictions</template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="geo_bl">Geolocation Blacklist</label>
              <MultiSelect
                inputId="geo_bl"
                v-model="form.geos_blacklist"
                @change="handleBlWl('geos_blacklist')"
                :options="countryList"
                optionLabel="name.common"
                optionValue="cca2"
                placeholder="Select Countries"
                display="chip"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="geo_wl">Geolocation Whitelist</label>
              <MultiSelect
                inputId="geo_wl"
                v-model="form.geos_whitelist"
                @change="handleBlWl('geos_whitelist')"
                :options="countryList"
                optionLabel="name.common"
                optionValue="cca2"
                placeholder="Select Countries"
                display="chip"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="seller_bl">Seller Blacklist</label>
              <MultiSelect
                inputId="seller_bl"
                v-model="form.sel_bl"
                @change="handleBlWl('sel_bl')"
                :options="sellersList"
                option-value="value"
                option-label="label"
                placeholder="Select Item"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="seller_wl">Seller Whitelist</label>
              <MultiSelect
                inputId="seller_wl"
                v-model="form.sel_wl"
                @change="handleBlWl('sel_wl')"
                :options="sellersList"
                option-value="value"
                option-label="label"
                placeholder="Select Item"
                filter
                class="w-full"
              />
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Others</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="priority">Priority</label>
              <InputNumber
                inputId="priority"
                v-model="form.priority"
                :min="0"
                :max="100"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="active">Active Status</label>
              <Dropdown
                inputId="active"
                v-model="form.active"
                :options="activeStatus"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Status"
                class="w-full"
              />
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";

import Card from "primevue/card";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import InputGroup from "primevue/inputgroup";
import MultiSelect from "primevue/multiselect";

import { sortByField } from "../helpers";
import { ref } from "vue";
import { onMounted } from "vue";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import Listbox from "primevue/listbox";

const store = useStore();
const toast = useToast();
const router = useRouter();

const submitted = ref(false);

const partner = JSON.parse(localStorage.getItem("partner"));
const token = localStorage.getItem("token");

const activeStatus = [
  { label: "Inactive", value: false },
  { label: "Active", value: true },
];

const blWlOpposite = {
  geos_blacklist: "geos_whitelist",
  geos_whitelist: "geos_blacklist",
  sel_bl: "sel_wl",
  sel_wl: "sel_bl",
};

const form = ref({
  name: "",
  url: "",
  cpm: 0,
  geos_blacklist: [],
  geos_whitelist: [],
  tag_group: 0,
  active: activeStatus[0].value,
  partner: "",
  priority: 0,
  sel_bl: [],
  sel_wl: [],
  wc_percent: 0,
  tag_type: "vast",
  owner_partner_id: partner.id,
});

const macros = [
  "[geo]",
  "[ua]",
  "[ip]",
  "[width]",
  "[height]",
  "[episodeId]",
  "[showId]",
  "[showTitle]",
  "[itunesCategory]",
  "[iabCategory]",
  "[rssFeed]",
  "[adType]",
  "[language]",
  "[sessionId]",
  "[cb]",
  "[declaredDomain]",
  "[referrerDomain]",
];

const tagGroups = ref([]);
const countryList = ref([]);
const sellersList = ref([]);
const macroPanel = ref();
const tagId = router.currentRoute.value.params.tagId;

if (tagId) {
  form.value.tagId = tagId;
}

const toggleMacro = (e) => macroPanel.value.toggle(e);

const handleMacroAdd = (e) => {
  form.value.url += e.value;
  toggleMacro();
};

const handleBlWl = (keyName) => {
  if (form.value[keyName].length) {
    form.value[blWlOpposite[keyName]] = [];
  }
};

const fetchCountryList = async () => {
  try {
    store.commit("setLoading", true);

    const response = await axios.get(
      "https://restcountries.com/v3.1/all?fields=name,cca2"
    );
    if (response.data) {
      countryList.value = response.data;
      sortByField(countryList.value, "name", "common");
    }
  } catch (error) {
    console.error("Error fetching countries:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchSellers = async () => {
  try {
    store.commit("setLoading", true);

    const response = await axios.get(`${API_BASE_URL}/sellers/by/partner`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      sellersList.value = response.data.sellers.map((item) => ({
        value: `${partner.id}_${item.id}`,
        label: `${item.name} (${item.id})`,
      }));
      sortByField(sellersList.value, "label");
    }
  } catch (error) {
    console.error("Error fetching sellers:", error);
  } finally {
    store.commit("setLoading", false);
    fetchTag();
  }
};

const fetchTagGroups = async () => {
  try {
    store.commit("setLoading", true);

    const response = await axios.get(`${API_BASE_URL}/tag-group`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      tagGroups.value = response.data;
    }
  } catch (error) {
    console.error("Error fetching tag groups:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchTag = async () => {
  try {
    if (!tagId) return;
    store.commit("setLoading", true);

    const response = await axios.get(API_BASE_URL + "/tag/" + tagId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      let tagData = response.data.tag;

      form.value.name = tagData.name;
      form.value.url = tagData.url;
      form.value.cpm = tagData.cpm;
      form.value.geos_blacklist = tagData.geos_blacklist?.length
        ? tagData.geos_blacklist.toUpperCase().split(",")
        : [];
      form.value.geos_whitelist = tagData.geos_whitelist?.length
        ? tagData.geos_whitelist.toUpperCase().split(",")
        : [];
      form.value.wc_percent = tagData.wc_percent * 100;
      form.value.tag_group = tagData.tag_group;
      form.value.active = tagData.active;
      form.value.priority = tagData.priority;
      form.value.tag_type = tagData.tag_type;

      form.value.sel_bl = tagData.sel_bl?.length
        ? tagData.sel_bl?.split(",").includes(partner.id)
          ? sellersList.value.map((item) => item.value)
          : tagData.sel_bl.split(",")
        : [];
      form.value.sel_wl =
        tagData.sel_wl?.length &&
        !tagData.sel_wl.split(",")?.includes(partner.id)
          ? tagData.sel_wl.split(",")
          : [];
    }
  } catch (error) {
    console.error("Error fetching tag:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const submitTag = async () => {
  submitted.value = true;
  if (form.value.name && form.value.url && form.value.cpm)
    try {
      const hasWhiteList = form.value.sel_wl.length > 0;
      const hasBlackList = form.value.sel_bl.length > 0;
      const isWhiteListFull =
        hasWhiteList && form.value.sel_wl.length === sellersList.value.length;
      const isBlackListFull =
        hasBlackList && form.value.sel_bl.length === sellersList.value.length;

      if (!hasWhiteList && !hasBlackList) {
        form.value.sel_wl = [partner.id];
      } else {
        if (isWhiteListFull) {
          form.value.sel_wl = [partner.id];
        }

        if (isBlackListFull) {
          form.value.sel_bl = [partner.id];
        }
      }

      const selectedTagGroup = tagGroups.value.find(
        (item) => item.id === form.value.tag_group
      ) || { name: "" };
      form.value.partner = selectedTagGroup.name;
      store.commit("setLoading", true);
      const response = await axios.post(API_BASE_URL + "/add-tag", form.value, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data?.success) {
        // Clear form after successful submission
        form.value = {
          name: "",
          url: "",
          cpm: 0,
          geos_blacklist: [],
          geos_whitelist: [],
          tag_group: 0,
          active: activeStatus[0].value,
          partner: "",
          priority: 0,
          sel_bl: [],
          sel_wl: [],
        };

        submitted.value = false;

        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Tag ${tagId ? "updated" : "added"} successfully!`,
          life: 4000,
        });
        router.go(-1);
      } else {
        // Handle unsuccessful submission
        toast.add({
          severity: "error",
          summary: "Error",
          detail: `Something went wrong...`,
          life: 4000,
        });
      }
    } catch (error) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: `Something went wrong...`,
        life: 4000,
      });
      console.log(error);
    } finally {
      store.commit("setLoading", false);
    }
};

onMounted(() => {
  fetchSellers();
  fetchCountryList();
  fetchTagGroups();
});
</script>
