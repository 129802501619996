<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Shows Management</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button class="justify-content-center" @click="goToCreateNewShow()"
            >Add New Show</Button
          >
          <br />
          <br />
          <InputText
            class="w-full sm:w-20rem"
            v-model="showsTableFilter.global.value"
            type="text"
            placeholder="Global Search"
          ></InputText>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="shows"
          :paginator="true"
          :rows="10"
          :filters="showsTableFilter"
          class="p-datatable-sm"
          :striped-rows="true"
        >
          <Column field="show_title" header="Title" :sortable="true"></Column>
          <Column field="rss_url" header="RSS link" :sortable="true"></Column>
          <Column field="last_fetched" header="Last Fetched" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.last_fetched || "Never" }}
            </template>
          </Column>
          <!--
          <Column header="Fetch Status">
            <template #body="slotProps">
              <Badge
                :value="slotProps.data.fetch_status || 'pending'"
                :severity="
                  slotProps.data.fetch_status == 'pending'
                    ? 'secondary'
                    : slotProps.data.fetch_status == 'in_progress'
                    ? 'info'
                    : slotProps.data.fetch_status == 'error'
                    ? 'danger'
                    : slotProps.data.fetch_status == 'fetched'
                    ? 'success'
                    : 'secondary'
                "
              ></Badge>
            </template>
          </Column>
        -->
          <Column header="Actions">
            <template #body="slotProps">
              <div class="flex gap-2">
                <Button
                  icon="pi pi-pencil"
                  outlined
                  rounded
                  @click="
                    goToUpdateShow(
                      slotProps.data.show_id,
                      slotProps.data.fetch_status
                    )
                  "
                />
                <Button
                  :loading="fetchLoading == slotProps.data.show_id"
                  icon="pi pi-refresh"
                  outlined
                  rounded
                  @click="triggerFetchShow(slotProps.data.show_id)"
                />
                <Button
                  icon="pi pi-trash"
                  outlined
                  rounded
                  severity="danger"
                  @click="confirmDeleteShow(slotProps.data.show_id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from "primevue/badge";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const fetchLoading = ref("");
const store = useStore();
const router = useRouter();
const toast = useToast();
const showsTableFilter = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const token = localStorage.getItem("podcasterToken");

const goToCreateNewShow = () => {
  router.push({ name: "AddPodcastShow" });
};
const goToUpdateShow = (showId, fetchStatus) => {
  if (fetchStatus !== "fetched") {
    toast.add({
      severity: "warn",
      summary: "Edit not allowed",
      detail: "Show data is not fetched yet, please try again later.",
      life: 4000,
    });
    return;
  }
  router.push({
    name: "UpdatePodcastShow",
    params: { showId },
  });
};

const triggerFetchShow = async (showId) => {
  try {
    fetchLoading.value = showId;
    const response = await axios.post(
      API_BASE_URL + "/podcaster/shows/" + showId + "/fetch",
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Fetch started for the show`,
        life: 4000,
      });
    }
  } catch (error) {
    console.error("Error fetching shows:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Failed to start fetch for the show`,
      life: 4000,
    });
  } finally {
    fetchLoading.value = "";
  }
};

const shows = computed(() => {
  return store.getters.shows;
});

const fetchShows = () => {
  console.log(store.getters.shows, store.getters.shows.length);
  if (!store.getters.shows || store.getters.shows.length == 0) {
    store.dispatch("loadShows", { token });
  }
  console.log(shows);
};

const deleteShow = async (showId) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/podcaster/shows/${showId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Show deleted successfully",
        life: 4000,
      });
      fetchShows(); // Refresh the shows list
    }
  } catch (error) {
    console.error("Error deleting show:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to delete the show",
      life: 4000,
    });
  }
};

const confirmDeleteShow = (showId) => {
  confirm.require({
    message: "Are you sure you want to delete this show?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    accept: () => {
      deleteShow(showId);
    },
    reject: () => {
      // optional: handle rejection
    },
  });
};

onMounted(() => {
  fetchShows();
});
</script>
