<template>
  <LayoutApp
    v-if="
      store.getters.isLoggedIn ||
      store.getters.isSellerLoggedIn ||
      store.getters.isGroupAdminLoggedIn ||
      store.getters.isAdminLoggedIn ||
      store.getters.isPodcasterLoggedIn ||
      store.getters.isPodcastAdminLoggedIn
    "
  />
  <LayoutLogin v-else />
  <Toast />
  <ConfirmDialog :style="{ maxWidth: '40rem' }" />
  <Loader v-if="store.getters.isLoading" />
</template>

<script setup>
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import LayoutLogin from "@/components/layouts/LayoutLogin.vue";
import LayoutApp from "@/components/layouts/LayoutApp.vue";
import { useStore } from "vuex";
import Loader from "./components/Loader.vue";

const store = useStore();
</script>
<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
