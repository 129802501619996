<template>
  <svg
    width="908"
    height="244"
    viewBox="0 0 908 244"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M673.334 130.912C673.334 151.25 673.184 171.613 673.435 191.952C673.51 197.773 671.732 200.297 665.599 199.997C657.687 199.622 649.776 199.922 641.84 199.897C632.302 199.897 631.901 199.497 631.876 190.178C631.876 154.473 631.901 118.744 631.851 83.0393C631.851 70.7214 630.073 58.8782 619.584 50.533C608.092 41.3882 595.074 38.7398 581.555 44.7613C568.461 50.583 560.6 60.8521 560.6 75.8435C560.575 114.047 560.6 152.25 560.55 190.453C560.55 199.423 560.05 199.872 550.937 199.922C543.026 199.947 535.089 199.922 527.178 199.922C518.266 199.897 517.94 199.597 517.94 190.902C517.94 154.148 517.414 117.395 518.14 80.6657C518.516 61.6016 506.324 44.7363 485.995 42.0129C470.924 39.989 452.022 49.0838 447.966 67.998C446.965 72.6703 446.69 77.4425 446.69 82.2398C446.74 118.369 446.715 154.498 446.69 190.628C446.69 199.298 446.064 199.897 437.527 199.947C428.764 199.972 420.027 199.847 411.265 199.972C406.683 200.047 404.255 198.448 404.255 193.526C404.33 152.2 402.803 110.823 404.906 69.597C406.182 44.5364 420.352 24.7728 442.283 10.9057C458.381 0.736573 475.831 -1.33724 493.856 0.711588C508.577 2.38563 521.67 8.95685 532.811 18.9261C539.02 24.4729 538.945 24.323 544.953 19.076C558.522 7.23284 574.019 0.761559 592.47 0.161903C619.433 -0.73758 640.613 9.83135 657.087 30.2196C667.176 42.7375 673.259 57.1542 673.259 73.6197C673.259 92.7088 673.259 111.823 673.259 130.912H673.284H673.334Z"
      :fill="props.logoColor"
    />
    <path
      d="M738.546 0.0502834C740.223 0.0502834 741.876 0.0502834 743.553 0.0502834C756.622 0.0502834 767.028 5.41387 774.772 16.141C785.237 30.6077 795.877 44.9495 806.242 59.4911C808.745 62.9891 810.272 63.4889 813.026 59.766C826.62 41.4015 840.465 23.237 854.009 4.84753C856.512 1.44948 859.291 -0.0246736 863.422 0.000312075C875.915 0.12524 888.433 0.000312079 900.925 0.075269C903.153 0.075269 905.882 -0.524386 907.284 1.67435C908.861 4.1979 906.333 5.84695 905.031 7.54598C884.001 35.38 863.047 63.264 841.742 90.8981C837.911 95.8703 837.811 99.2683 841.566 104.39C863.297 134.023 884.627 163.956 906.133 193.789C909.237 198.112 908.461 200.435 903.228 200.535C889.684 200.76 876.14 200.61 862.621 200.51C858.59 200.485 855.786 198.361 853.483 194.963C840.164 175.4 826.495 156.061 813.377 136.347C809.847 131.025 808.545 132.824 805.941 136.722C793.148 155.886 780.055 174.875 767.337 194.089C764.232 198.786 760.477 200.835 754.944 200.735C743.478 200.56 732.037 200.735 720.571 200.66C717.967 200.66 714.738 201.46 713.235 198.511C711.883 195.813 714.412 193.839 715.764 191.915C732.763 168.079 749.887 144.317 766.961 120.531C771.192 114.634 775.273 108.588 779.804 102.916C782.784 99.1934 782.784 96.1451 779.879 92.4472C767.887 77.0311 756.046 61.515 744.179 45.9989C734.465 33.3062 724.752 20.5885 715.113 7.82082C713.911 6.22174 712.134 4.47274 713.135 2.22404C714.287 -0.349487 716.966 0.100255 719.194 0.075269C725.653 0.000312079 732.112 0.0502834 738.571 0.0502834H738.546Z"
      :fill="props.logoColor"
    />
    <path
      d="M126.559 62.7278C126.559 82.4415 126.484 74.8103 126.559 94.549C126.735 134.001 110.512 164.659 76.8894 185.672C67.0254 191.843 55.9598 195.166 44.3685 197.515C32.3014 199.989 20.3346 198.939 8.34264 199.564C2.58451 199.864 -0.144337 197.79 0.0058752 191.718C0.256228 182.598 0.206158 173.454 0.0309105 164.309C-0.0692308 158.987 2.28409 156.838 7.56654 156.988C14.8518 157.188 22.1621 156.738 29.4474 157.038C53.8318 158.013 81.9966 133.227 83.2483 106.392C84.425 81.2567 82.9729 83.5658 82.9229 58.4802C82.8728 31.2958 82.8728 36.6899 82.8728 9.50553C82.8728 0.585646 83.3735 0.110918 92.5114 0.0859328C100.848 0.0609471 109.185 0.285818 117.497 0.0109758C123.48 -0.188909 125.408 2.33464 125.558 8.13132C125.959 22.648 126.61 37.1397 126.459 51.6813C126.309 66.198 126.434 48.1362 126.434 62.6778H126.484L126.559 62.7278Z"
      :fill="props.logoColor"
    />
    <path
      d="M287.12 97.2845C287.12 112.096 275.113 124.104 260.301 124.104C245.49 124.104 233.482 112.096 233.482 97.2845C233.482 82.4728 245.49 70.4656 260.301 70.4656C275.113 70.4656 287.12 82.4728 287.12 97.2845Z"
      :fill="props.logoColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M266.494 159.509C264.287 159.742 262.045 159.862 259.776 159.862C254.028 159.862 248.461 159.094 243.169 157.654L256.118 190.329C257.064 192.715 256.104 195.433 253.869 196.696C253.131 197.113 252.281 197.304 251.436 197.229C200.756 192.743 160.944 150.325 160.944 98.5789C160.944 56.6753 187.001 20.8658 223.811 6.35537C225.906 5.52948 228.274 5.98455 229.986 7.44809L260.641 33.661C269.162 33.7756 277.275 35.5791 284.661 38.7519L271.931 3.14859C271.409 1.68993 272.594 0.191616 274.128 0.404602C322.455 7.11274 359.72 48.4926 359.72 98.5789V238.912C359.72 243.391 354.343 245.677 351.118 242.568L267.074 160.185C266.861 159.976 266.667 159.75 266.494 159.509ZM238.793 149.55C245.284 152.132 252.364 153.552 259.776 153.552C291.142 153.552 316.569 128.125 316.569 96.7587C316.569 65.3927 291.142 39.9656 259.776 39.9656C228.41 39.9656 202.982 65.3927 202.982 96.7587C202.982 100.992 203.446 105.118 204.324 109.088C208.379 127.011 220.898 141.724 237.459 148.804C237.932 149.006 238.379 149.257 238.793 149.55Z"
      :fill="props.logoColor"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  logoColor: {
    type: String,
    default: "#000000",
  },
});
</script>
