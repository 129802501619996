<template>
  <v-card
    :ripple="false"
    class="overview-card elevation-0"
    :class="{ active: isActive }"
    @click="$emit('click')"
  >
    <widget-loader v-if="loading" />
    <div v-else class="overview-card-content">
      <div class="overview-card-header">
        <v-icon :color="iconColor" class="mr-2">{{ getIcon(title) }}</v-icon>
        <span class="overview-card-label">{{ title }}</span>
      </div>
      <div class="overview-card-body">
        <h2 class="overview-card-title">{{ formatNumber(total) }}</h2>
      </div>
    </div>
  </v-card>
</template>

<script setup>
import { defineProps, computed } from "vue";
import WidgetLoader from "./WidgetLoader.vue";

const props = defineProps({
  loading: Boolean,
  isActive: Boolean,
  title: String,
  total: [Number, String],
});

const iconColor = computed(() =>
  props.isActive ? "rgba(215, 253, 68, 1)" : "#7f88a8"
);

function getIcon(title) {
  switch (title.toLowerCase()) {
    case "views":
      return "mdi-eye-outline";
    case "sessions":
      return "mdi-play-circle-outline";
    case "unique listeners":
      return "mdi-account-outline";
    case "ctr":
      return "mdi-cursor-pointer";
    default:
      return "mdi-chart-bar";
  }
}

function formatNumber(value) {
  if (value * 1 == value) {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + "K";
    }
    return value.toLocaleString();
  }
  return value;
}
</script>
<style>
.overview-card:hover > .v-card__overlay {
  opacity: 0 !important;
  background: transparent !important;
}

.overview-card .v-ripple__animation--in,
.overview-card .v-ripple__animation--out {
  opacity: 0 !important;
}

.overview-card .v-ripple__container,
.overview-card .v-card__overlay {
  display: none !important;
}
</style>

<style scoped>
.overview-card {
  background: var(--overview-card-bg);
  border-radius: 10px;
  color: var(--text-color);
  padding: 12px;
  border: 1px solid var(--gray-800);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensure overlay doesn't exceed card boundaries */
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.overview-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--hover-overlay-color, rgba(101, 109, 121, 0.2));
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.overview-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.overview-card:hover::after {
  opacity: 1;
}
.overview-card-item {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  min-height: 120px;
  height: 100%;
}

.overview-card-label {
  font-family: Figtree, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.7;
}

.overview-card-title {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.overview-card-description {
  font-family: Figtree, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--description-color, #7f88a8);
}

.overview-card.active {
  border: 2px solid var(--active-border-color, rgba(215, 253, 68, 1));
  background: var(--active-bg-color, rgba(215, 253, 68, 0.1));
}

.overview-card.active .overview-card-description {
  color: var(--text-color);
}
</style>
