<template>
  <Dialog
    :visible="visible"
    modal
    header="Ads.txt file validation result"
    :closable="false"
    style="width: 40rem; max-width: 92vw"
  >
    <div v-if="missingLines?.length || partnerStatusArray.length">
      <p>
        <b>{{
          adstxtVerified ? "Validation passed." : "Validation failed."
        }}</b>
        {{
          lastChecked?.length
            ? `Last checked: ${new Date(lastChecked).toLocaleString()}`
            : ""
        }}
      </p>

      <div v-if="missingLines?.length">
        <p>
          You need to add missing lines (below) to your current ads.txt file.
        </p>
        <div
          class="surface-50 surface-border text-color-secondary border-1 overflow-x-hidden border-round-md p-3 mb-2 relative"
        >
          <Button
            @click="codeToClipboard()"
            icon="pi pi-copy"
            text
            aria-label="Copy"
            severity="secondary"
            v-tooltip.bottom="'Copy'"
            class="absolute right-0 top-0 embed-code-copy-btn"
          />
          <pre ref="adstxtRef">{{ adstxtContent }}</pre>
        </div>
      </div>

      <div v-if="partnerStatusArray.length" class="mt-4">
        <h3>Partner Status</h3>
        <div
          v-for="partner in partnerStatusArray"
          :key="partner.name"
          class="mb-2"
        >
          <div class="flex align-items-center">
            <Badge
              :severity="partner.status === 'GREEN' ? 'success' : 'danger'"
              class="mr-2"
            />
            <span class="font-bold">{{ partner.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-lg">
      Verification not done yet. Check back later.
    </div>
    <template #footer>
      <Button
        label="Close"
        outlined
        icon="pi pi-check"
        @click="emits('close-modal')"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script setup>
import Button from "primevue/button";
import Badge from "primevue/badge";
import { computed, ref } from "vue";

const props = defineProps({
  visible: Boolean,
  missingLines: String,
  lastChecked: String,
  adsTxtPerDemand: [String, Object],
});

const emits = defineEmits(["close-modal"]);
const adstxtRef = ref(null);

const codeToClipboard = () => {
  let clipboardData = adstxtRef.value.innerText;
  navigator.clipboard.writeText(clipboardData);
};

const adstxtContent = computed(() => {
  if (props.missingLines?.length) {
    let contentTxt = "";
    JSON.parse(props.missingLines).map((item) => {
      contentTxt += `${item}\n`;
    });
    return contentTxt;
  } else return "";
});

const partnerStatusArray = computed(() => {
  try {
    const parsedData =
      typeof props.adsTxtPerDemand === "string"
        ? JSON.parse(props.adsTxtPerDemand)
        : props.adsTxtPerDemand;

    if (!parsedData) return [];

    return Object.entries(parsedData).map(([name, status]) => ({
      name,
      status,
    }));
  } catch (e) {
    console.error("Error parsing ads_txt_per_demand:", e);
    return [];
  }
});

const adstxtVerified = computed(() => {
  return partnerStatusArray.value.every(
    (partner) => partner.status === "GREEN"
  );
});
</script>

<style scoped>
.embed-code-copy-btn {
  opacity: 0.5;
  transition: opacity 0.3s;
}
.embed-code-copy-btn:hover {
  opacity: 1;
}
</style>
