<template>
  <div class="country-item mb-3">
    <v-row no-gutters align="center" class="mb-1">
      <v-col cols="6">
        <div class="country-name">{{ country }}</div>
      </v-col>
      <v-col cols="3" class="text-right">
        <div>{{ formattedCount }}</div>
      </v-col>
      <v-col cols="3" class="text-right">
        <div>{{ percentageShare }}%</div>
      </v-col>
    </v-row>
    <v-progress-linear
      :model-value="percentageShare"
      color="primary"
      height="6"
      rounded
    ></v-progress-linear>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { byIso } from "country-code-lookup";

const props = defineProps({
  geo: String,
  count: Number,
  totalCount: Number,
});

const country = computed(() => {
  try {
    const iso = byIso(props.geo);
    return iso ? iso.country : `Unknown (${props.geo})`;
  } catch (err) {
    console.error("Error:", err, props.geo);
    return `Error (${props.geo})`;
  }
});

const formattedCount = computed(() => {
  return new Intl.NumberFormat().format(props.count);
});

const percentageShare = computed(() => {
  return ((props.count / props.totalCount) * 100).toFixed(1);
});
</script>

<style scoped>
.country-item {
  color: var(--text-color);
  font-size: 12px;
}

.country-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.v-col {
  font-weight: 500;
}
</style>
