<template>
  <div
    class="flex align-items-center h-full px-3 w-full sm:w-9 md:w-7 lg:w-6 xl:w-5 mx-auto"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full">
      <div class="text-center mb-5">
        <img
          :src="require('@/assets/pulsar.svg')"
          alt="Image"
          height="50"
          class="mb-3"
        />
        <div class="text-900 text-3xl font-medium mb-3">Admin Login</div>
        <span class="text-600 font-medium line-height-3"
          >Are you a partner?</span
        >
        <router-link
          :to="{ name: 'Login' }"
          class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
          >Login here</router-link
        >
      </div>

      <div class="">
        <form @submit.prevent="submitLoginForm">
          <label for="email1" class="block text-900 font-medium mb-2"
            >Email</label
          >
          <InputText
            v-model="loginForm.username"
            id="email1"
            placeholder="Email"
            required
            @input="errorMessage = ''"
            type="email"
            class="w-full mb-3"
          />

          <label for="password1" class="block text-900 font-medium mb-2"
            >Password</label
          >
          <InputText
            id="password1"
            type="password"
            v-model="loginForm.password"
            placeholder="Password"
            required
            @input="errorMessage = ''"
            class="w-full mb-3"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <Button
            type="submit"
            label="Sign In"
            icon="pi pi-user"
            class="w-full mt-3"
          ></Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

export default {
  name: "AdminLogin",
  components: {
    InputText,
    Button,
  },
  setup() {
    const loginForm = ref({
      username: "",
      password: "",
    });

    const store = useStore();
    const router = useRouter();
    const errorMessage = ref("");

    const submitLoginForm = async () => {
      try {
        const response = await axios.post(API_BASE_URL + "/admin/login", {
          username: loginForm.value.username,
          password: loginForm.value.password,
        });
        if (response.data.success) {
          store.commit("setLoggedIn", true);
          localStorage.setItem("adminToken", response.data.token);
          store.commit("setAdmin", response.data.admin);

          // Redirect to the dashboard
          router.push("/admin/dashboard");
        } else {
          // Handle failed login
        }
      } catch (error) {
        // Handle errors
        errorMessage.value = "Invalid email or password.";
      }
    };

    return {
      loginForm,
      submitLoginForm,
      errorMessage,
    };
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-bottom: 1rem;
}
</style>
