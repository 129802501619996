<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Episodes</h2>
    <Card>
      <template #header>
        <div class="pt-4 px-4">
          <MultiSelect
            v-model="episodesFilter.global.value"
            :options="shows"
            option-label="show_title"
            option-value="show_id"
            placeholder="Filter by shows"
            class="w-full md:w-20rem"
          ></MultiSelect>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="episodes"
          :filters="episodesFilter"
          :global-filter-fields="['show_id']"
          :paginator="true"
          :rows="10"
          class="p-datatable-sm"
          :striped-rows="true"
        >
          <Column field="episode_number" header="ID" :sortable="true"></Column>
          <Column field="title" header="Title" :sortable="true"></Column>
          <Column
            field="media_type"
            header="Media Type"
            :sortable="true"
          ></Column>
          <Column header="Edit">
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-center">
                <Button
                  icon="pi pi-eye"
                  outlined
                  rounded
                  severity="help"
                  :loading="loadingEpisodeId === slotProps.data.episode_id"
                  @click="fetchEpisodeDetail(slotProps.data.episode_id)"
                />
              </div>
            </template>
          </Column>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="refreshEpisdoes()"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="episodes" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <PodcasterEpisodeDetailDialog
      :episode="episodeDetail"
      :open="openEpisodeDetail"
      @close="handleModalClose"
    />
  </div>
</template>

<script setup>
import { FilterMatchMode } from "primevue/api";
import Card from "primevue/card";
import MultiSelect from "primevue/multiselect";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import { onBeforeMount, ref, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { formatTime } from "@/helpers";
import PodcasterEpisodeDetailDialog from "./PodcasterEpisodeDetailDialog.vue";

const store = useStore();

const loadingEpisodeId = ref("");
const openEpisodeDetail = ref(false);
const episodeDetail = ref({
  title: "",
  description: "",
  publication_date: "",
  episode_link: "",
  media_url: "",
  media_type: "",
  duration: "",
  image_url: "",
  keywords: "",
  author: "",
});

const episodesFilter = ref({
  global: { value: null, matchMode: FilterMatchMode.IN },
});

const token = localStorage.getItem("podcastAdminToken");

const handleModalClose = () => {
  openEpisodeDetail.value = false;
};

const shows = computed(() => {
  let tempShowList = store.getters.shows.filter(
    (item) => item.show_title != null && item.show_title != ""
  );
  if (!tempShowList) {
    tempShowList = [];
  }
  return tempShowList;
});

const episodes = computed(() => {
  let tempEpisodes = store.getters.episodes;
  if (!tempEpisodes) {
    tempEpisodes = [];
  }
  return tempEpisodes;
});

const fetchShows = () => {
  if (!store.getters.shows || store.getters.shows.length == 0) {
    store.dispatch("loadShows", { token });
  }
};

const refreshEpisdoes = () => {
  store.dispatch("loadEpisodes", { token });
};

const fetchEpisodes = async () => {
  if (!store.getters.episodes || store.getters.episodes.length == 0) {
    store.dispatch("loadEpisodes", { token });
  }
};

const fetchEpisodeDetail = async (episodeId) => {
  try {
    loadingEpisodeId.value = episodeId;
    const response = await axios.get(
      API_BASE_URL + "/podcaster/episodes/detail/" + episodeId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data?.episode) {
      episodeDetail.value = {
        ...episodeDetail.value,
        ...response.data.episode,
        duration: formatTime(response.data.episode.duration || 0),
        publication_date: new Date(
          response.data.episode.publication_date || 0
        ).toLocaleString(),
      };
    }
  } catch (error) {
    console.error("Error fetching episode detail:", error);
  } finally {
    loadingEpisodeId.value = "";
    openEpisodeDetail.value = true;
  }
};

onBeforeMount(() => {
  fetchShows();
  fetchEpisodes();
});
</script>
