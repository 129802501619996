<template>
  <div>
    <TabView>
      <TabPanel
        v-for="(configs, category) in categorizedConfig"
        :header="category"
        :key="category"
      >
        <DataTable :value="Object.entries(configs)">
          <Column field="0" header="Key"></Column>
          <Column field="1" header="Value"></Column>
          <Column>
            <template #body="{ data }">
              <Button
                icon="pi pi-pencil"
                outlined
                rounded
                @click="openDialog(data['0'], data['1'], category)"
              />
            </template>
          </Column>
        </DataTable>
      </TabPanel>
    </TabView>

    <Dialog
      v-model:visible="display"
      :header="`Edit ${selectedKey}`"
      :modal="true"
      :closable="false"
      class="w-10 sm:w-7 md:w-5 lg:w-4 xl:w-3"
    >
      <div class="flex flex-column gap-1 mb-3">
        <label for="value">Value:</label>
        <InputText id="value" :type="inputType" v-model="selectedValue" />
      </div>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="display = false"
        />
        <Button
          class="flex-1 sm:flex-initial"
          @click="saveValue"
          label="Submit"
        />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, defineProps } from "vue";
import axios from "axios";
import Button from "primevue/button";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import { API_BASE_URL } from "@/consts";

// category mapping
const configDict = {
  bbp: { name: "Global Percent", order: 1, category: "BB" },
  bbpg_1: { name: "Tag Group Percent: Triton", order: 2, category: "BB" },
  bbpg_2: { name: "Tag Group Percent: Adtonos", order: 3, category: "BB" },
  bbpg_7: { name: "Tag Group Percent: Audio ad", order: 4, category: "BB" },
  bbpg_8: { name: "Tag Group Percent: Adswizz", order: 5, category: "BB" },
  bbpg_9: { name: "Tag Group Percent: Remixed", order: 5, category: "BB" },
  bbpg_10: {
    name: "Tag Group Percent: Instreamatic",
    order: 6,
    category: "BB",
  },
  bbpg_11: { name: "Tag Group Percent: Amitay", order: 7, category: "BB" },
  bbpg_12: { name: "Tag Group Percent: Spreaker", order: 8, category: "BB" },
  bbpg_13: { name: "Tag Group Percent: Zeno", order: 9, category: "BB" },
  bbpg_14: { name: "Tag Group Percent: Consumable", order: 10, category: "BB" },
  bbpg_15: {
    name: "Tag Group Percent: Winvid apnx",
    order: 11,
    category: "BB",
  },
  bbpg_16: { name: "Tag Group Percent: Vdo", order: 12, category: "BB" },
  bbpgb: { name: "Geo Percent: GB", order: 13, category: "BB" },
  bbpuk: { name: "Geo Percent: UK", order: 14, category: "BB" },
  bbpus: { name: "Geo Percent: US", order: 15, category: "BB" },
  bbpes: { name: "Geo Percent: ES Percent", order: 16, category: "BB" },
  bbpfr: { name: "Geo Percent: FR Percent", order: 17, category: "BB" },
  bbpnl: { name: "Geo Percent: NL Percent", order: 18, category: "BB" },
  bb_aato: { name: "After Ad Timeout", order: 19, category: "BB" },
  bb_cfactor: { name: "Completion Factor", order: 20, category: "BB" },
  bb_idto: { name: "Idle Timeout", order: 21, category: "BB" },
  bb_ma: { name: "Max Ads Per Session", order: 22, category: "BB" },
  bb_mr: { name: "Max Requests Per Session", order: 23, category: "BB" },
  bb_mrp: { name: "Midroll Percent", order: 24, category: "BB" },
  bb_noto: { name: "No Ad Timeout", order: 25, category: "BB" },
  bb_stto: { name: "Start Timeout", order: 26, category: "BB" },
  bb_stto_1: { name: "Start Timeout: Triton", order: 27, category: "BB" },
  bb_stto_2: { name: "Start Timeout: Adtonos", order: 28, category: "BB" },
  bb_stto_7: { name: "Start Timeout: Audio ad", order: 29, category: "BB" },
  bb_stto_8: { name: "Start Timeout: Adswizz", order: 30, category: "BB" },
  bb_stto_9: { name: "Start Timeout: Remixed", order: 31, category: "BB" },
  bb_stto_12: { name: "Start Timeout: Spreaker", order: 32, category: "BB" },

  cpmf: { name: "CPM Factor", order: 1, category: "Factoring" },
  impf: { name: "Impressions Factor", order: 2, category: "Factoring" },

  avg_cpm_adswizz: { name: "Adswizz Avg. CPM", order: 1, category: "CPM" },
  avg_cpm_adtonos: { name: "Adtonos Avg. CPM", order: 2, category: "CPM" },
  avg_cpm_audioad: { name: "Audioad Avg. CPM", order: 3, category: "CPM" },
  avg_cpm_consumable: {
    name: "Consumable Avg. CPM",
    order: 4,
    category: "CPM",
  },
  avg_cpm_instreamtic: {
    name: "Instreamatic Avg. CPM",
    order: 5,
    category: "CPM",
  },
  avg_cpm_spreaker: { name: "Spreaker Avg. CPM", order: 6, category: "CPM" },
  avg_cpm_triton: { name: "Triton Avg. CPM", order: 7, category: "CPM" },
  avg_cpm_zeno: { name: "Zeno Avg. CPM", order: 8, category: "CPM" },

  ab_init_percent: { name: "AB Init Percent", order: 1, category: "General" },
  loads_factor: { name: "Loads Factor", order: 2, category: "General" },
  ipmaxim: { name: "Max Ads Per IP", order: 3, category: "General" },
  ipmaxim_8: { name: "Max Ads Per IP: Adswizz", order: 4, category: "General" },
  dd_dids: { name: "DD Dids", order: 5, category: "General" },
  dd_rto: { name: "DD RTO", order: 6, category: "General" },
  statsd_ip: { name: "StatsD IP", order: 7, category: "General" },
  x_wc_geos: { name: "WC Geos", order: 8, category: "General" },
  dtdp: { name: "DevTools Detect Percent", order: 33, category: "General" },
};

function getConfigKey(configName) {
  for (let key in configDict) {
    if (configDict[key].name === configName) {
      return key;
    }
  }

  // return null; // Return null if no matching key was found.
  return configName; // Return configName itself if no matching key was found.
}

// declaring props
const props = defineProps(["sellerId", "subsellerId"]);
let configObject = ref({});
let config = ref({});
const categorizedConfig = ref({});

const display = ref(false);
const selectedKey = ref("");
const selectedValue = ref("");
const selectedCategory = ref("");
const inputType = ref("");

function openDialog(key, value, category) {
  selectedKey.value = key;
  selectedValue.value = value;
  inputType.value = typeof selectedValue.value === "string" ? "text" : "number";
  selectedCategory.value = category;
  display.value = true;
}

async function saveValue() {
  const token = localStorage.getItem("adminToken");
  const configKey = getConfigKey(selectedKey.value);

  const result = await axios.post(
    API_BASE_URL + "/set-config",
    {
      sellerId: props.sellerId,
      subSellerId: props.subsellerId,
      key: configKey,
      value: selectedValue.value,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (result.data.success) {
    categorizedConfig.value[selectedCategory.value][selectedKey.value] =
      selectedValue.value;
    fetchConfig();
  }

  // Close the dialog
  display.value = false;
}

const fetchConfig = async () => {
  try {
    const token = localStorage.getItem("adminToken");
    const resFromDB = await axios.get(API_BASE_URL + "/get-config", {
      headers: { Authorization: `Bearer ${token}` },
    });
    configObject.value = resFromDB.data; // Use .value to assign new value to reactive ref
  } catch (error) {
    console.log(error);
  }
};

const makeConfig = async () => {
  const finalConfig = await getConfig(props.sellerId, props.subsellerId);
  config.value = finalConfig;
  categorizedConfig.value = categorizeConfig(finalConfig);
};

onMounted(async () => {
  // const res = await axios.get("https://app.audiopulsar.com/config.json");
  await fetchConfig();
  makeConfig();

  watch([() => props.sellerId, () => props.subsellerId], makeConfig, {
    immediate: true,
  });
});

async function getConfig(seller, subSeller) {
  try {
    // Default configuration
    let finalConfig = { ...configObject.value.default };

    // Merge with specific seller configuration if it exists
    if (Object.prototype.hasOwnProperty.call(configObject.value, seller)) {
      finalConfig = { ...finalConfig, ...configObject.value[seller] };
    }

    if (subSeller) {
      // Merge with specific seller and sub-seller configuration if it exists
      const sellerSubSellerKey = `${seller}_${subSeller}`;

      if (
        Object.prototype.hasOwnProperty.call(
          configObject.value,
          sellerSubSellerKey
        )
      ) {
        finalConfig = {
          ...finalConfig,
          ...configObject.value[sellerSubSellerKey],
        };
      }
    }

    return finalConfig;
  } catch (error) {
    console.error(`Error fetching config: ${error}`);
    return null;
  }
}

function categorizeConfig(config) {
  const categorized = {};

  for (const [key, value] of Object.entries(config)) {
    const category = configDict[key]?.category || "Uncategorized";
    if (!categorized[category]) {
      categorized[category] = {};
    }

    if (configDict[key]?.name)
      categorized[category][configDict[key]?.name] = value;
    else categorized[category][key] = value;
  }
  return categorized;
}
</script>
