const emailValidator = (email) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const domainValidator = (domain) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/;
  return re.test(String(domain).toLowerCase());
};

const passwordValidator = (password) => {
  return !(
    !password ||
    password.length < 8 ||
    !(/[a-z]/.test(password) && /[A-Z]/.test(password))
  );
};

const formatCurrencyNumber = (number) => {
  if (number !== 0 && !number) return "NaN";

  return (
    "$" +
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

const adjustDate = (value) => {
  let adjusted = new Date(value);
  adjusted.setDate(adjusted.getDate() + 1);
  return adjusted;
};

const formatDate = (dateStr) => {
  if (!dateStr) return "Unknown";

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateObj = new Date(dateStr);

  const day = dateObj.getUTCDate();
  const month = months[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();

  // Convert 24-hour format to 12-hour format and determine AM/PM
  let hour12Format = hours % 12 || 12;
  const ampm = hours >= 12 ? "PM" : "AM";

  return `${month} ${day}, ${year}, ${hour12Format}:${String(minutes).padStart(
    2,
    "0"
  )} ${ampm}`;
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}hrs ${minutes}mins ${remainingSeconds}secs`;
};

const downloadTextFile = (text, filename) => {
  const blob = new Blob([text], { type: "text/plain" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const generateEmbedExport = (seller, subSellers, width, theme, layout) => {
  let textContent =
    "Please embed script for each seller where you want to display the player.";
  subSellers.forEach((subSeller) => {
    textContent += `\n\n<!-- For ${
      subSeller.name
    } -->\n<pulsar-player seller="${seller.id}" subseller="${
      subSeller.id
    }" domain="${subSeller.domain}"${layout ? ` layout="${layout}"` : ""}${
      theme ? ` theme="${theme}"` : ""
    } width="${width || 380}"></pulsar-player><script src="https://${
      seller.domain
    }/pulsar.player.js" type="module" crossorigin></script>`;
  });
  return textContent;
};

const generateEmbedGuide = (componentCode, componentSrc, adsTxt) =>
  `# Embed the player\nCopy & paste this code where you want to embed the player:\n${componentCode}\n\nPut this snippet before your body tag ends:\n${componentSrc}\n\n# ads.txt contents ({your_domain}/ads.txt)\n${adsTxt}\n`;

const exportCSV = (csvData) => {
  const headers = Object.keys(csvData[0]);
  const csv = [
    headers.join(","),
    ...csvData.map((obj) => headers.map((header) => obj[header]).join(",")),
  ].join("\n");
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
  );
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);
  link.click();
};

const sortByField = (array, field, second_field) => {
  // Sorting the array by the specified field
  array.sort((a, b) => {
    const valueA = (
      second_field ? a[field][second_field] : a[field]
    ).toUpperCase(); // convert values to uppercase for case-insensitive comparison
    const valueB = (
      second_field ? b[field][second_field] : b[field]
    ).toUpperCase();

    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0; // values are equal
  });
};

const isWithinOneWeek = (date) => {
  // Get the current date
  const currentDate = new Date();

  // Calculate the date one week (7 days) before the current date
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 7);

  // Convert the input date to a Date object if it's not already
  const inputDate = new Date(date);

  // Check if the input date is within the range
  return inputDate >= oneWeekAgo;
};

export {
  emailValidator,
  domainValidator,
  passwordValidator,
  formatCurrencyNumber,
  formatDate,
  formatTime,
  adjustDate,
  downloadTextFile,
  generateEmbedExport,
  generateEmbedGuide,
  exportCSV,
  sortByField,
  isWithinOneWeek,
};
