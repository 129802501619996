<template>
  <svg
    width="263"
    height="322"
    viewBox="0 0 263 322"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M166.943 128.078C166.943 147.656 151.057 163.526 131.459 163.526C111.862 163.526 95.9751 147.656 95.9751 128.078C95.9751 108.501 111.862 92.6302 131.459 92.6302C151.057 92.6302 166.943 108.501 166.943 128.078Z"
      :fill="props.logoColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M139.653 210.323C136.732 210.632 133.766 210.79 130.763 210.79C123.159 210.79 115.793 209.775 108.792 207.872L125.925 251.06C127.176 254.214 125.905 257.807 122.948 259.476C121.972 260.027 120.847 260.279 119.73 260.18C52.6741 254.251 0 198.185 0 129.789C0 74.4029 34.4757 27.0715 83.1785 7.89231C85.9505 6.80069 89.084 7.40218 91.3486 9.33662L131.909 43.9837C143.183 44.1351 153.917 46.5188 163.69 50.7125L146.846 3.65373C146.156 1.72576 147.723 -0.254651 149.754 0.0268639C213.694 8.89337 263 63.5874 263 129.789V315.275C263 321.195 255.885 324.216 251.618 320.107L140.42 211.217C140.138 210.941 139.882 210.642 139.653 210.323ZM103.002 197.16C111.59 200.573 120.957 202.45 130.763 202.45C172.264 202.45 205.906 168.841 205.906 127.383C205.906 85.9251 172.264 52.3167 130.763 52.3167C89.2632 52.3167 55.6206 85.9251 55.6206 127.383C55.6206 132.979 56.2336 138.432 57.3961 143.679C62.7611 167.369 79.3249 186.816 101.236 196.175C101.862 196.442 102.454 196.772 103.002 197.16Z"
      :fill="props.logoColor"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  logoColor: {
    type: String,
    default: "#000000",
  },
});
</script>
