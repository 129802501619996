<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{
        $router.currentRoute.value.name == "UpdatePartner" ? "Update" : "Add"
      }}
      Partner
    </h2>
    <form @submit.prevent="submitForm">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Partner Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 flex flex-column gap-2">
              <div class="flex flex-column gap-1">
                <label for="name">Name </label>
                <InputText
                  id="name"
                  v-model="form.name"
                  :class="{ 'p-invalid': submitted && !form.name }"
                />
                <small v-if="submitted && !form.name" class="p-error"
                  >Name is required</small
                >
              </div>

              <div class="flex flex-column gap-1">
                <label for="email">Email</label>
                <InputText
                  id="email"
                  type="email"
                  v-model="form.email"
                  :class="{
                    'p-invalid': submitted && (!form.email || !emailValid),
                  }"
                />
                <small v-if="submitted && !emailValid" class="p-error"
                  >Invalid email format</small
                >
              </div>

              <div class="flex flex-column gap-1">
                <label for="domain">Domain </label>
                <InputText
                  id="domain"
                  v-model="form.domain"
                  :class="{
                    'p-invalid': submitted && (!form.domain || !domainValid),
                  }"
                />
                <small v-if="submitted && !domainValid" class="p-error"
                  >Invalid domain format</small
                >
              </div>

              <div class="flex flex-column gap-1">
                <label for="canManageDemandTagsOptions"
                  >Can Manage Demand Tags</label
                >
                <SelectButton
                  id="canManageDemandTagsOptions"
                  v-model="form.can_manage_demand_tags"
                  :options="canManageDemandTagsOptions"
                  option-label="name"
                  option-value="value"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-2">
              <div class="flex flex-column gap-1">
                <label for="contractType">Contract Type</label>
                <Dropdown
                  id="contractType"
                  v-model="form.contractType"
                  :options="contractTypes"
                  placeholder="Select Type"
                  optionLabel="label"
                  optionValue="value"
                  @change="onContractTypeChange"
                />
              </div>

              <div v-if="form.contractType" class="flex flex-column gap-1">
                <template v-if="form.contractType === 'cpm'">
                  <label for="contractValue">{{ contractValueLabel }}:</label>
                  <InputText
                    id="contractValue"
                    v-model.number="form.contractValue"
                  />
                </template>
                <template v-else-if="form.contractType === 'revshare'">
                  <label for="contractValue">{{ contractValueLabel }}:</label>
                  <input
                    id="contractValue"
                    type="range"
                    min="0"
                    max="75"
                    step="5"
                    v-model="form.contractValue"
                  />
                  <span style="text-align: left; width: 20%"
                    >{{ form.contractValue }}%</span
                  >
                </template>
              </div>

              <div class="flex flex-column gap-1">
                <label for="password">Password</label>
                <InputText
                  id="password"
                  autocomplete="new-password"
                  type="password"
                  v-model="form.password"
                  placeholder="Enter password"
                  :class="{
                    'p-invalid':
                      submitted && (!form.password || !passwordValid),
                  }"
                />
                <small
                  v-if="submitted && !form.partnerId && !passwordValid"
                  class="p-error"
                  >Password must be at least 8 chars, with upper-case and
                  lower-case letters</small
                >
              </div>

              <div class="flex flex-column gap-1">
                <label for="fixImpCpm">Fix Impressions CPM </label>
                <InputNumber
                  id="fixImpCpm"
                  v-model="form.fixImpCpm"
                  inputId="minmaxfraction"
                  :minFractionDigits="0"
                  :maxFractionDigits="2"
                />
                <small v-if="submitted && !form.fixImpCpm" class="p-error"
                  >Fix Impressions CPM is required</small
                >
              </div>
              <div class="flex flex-column gap-1">
                <label for="canEmbedNewWidgetOptions"
                  >Can Embed New Widget</label
                >
                <SelectButton
                  id="canEmbedNewWidgetOptions"
                  v-model="form.can_embed_new_widget"
                  :options="canManageDemandTagsOptions"
                  option-label="name"
                  option-value="value"
                />
              </div>
              <div class="flex flex-column gap-1">
                <label for="defaultWidgetLayout">Default Widget Layout </label>
                <InputNumber
                  id="defaultWidgetLayout"
                  v-model="form.default_widget_layout"
                  inputId="minmaxfraction"
                  :maxFractionDigits="0"
                />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Seller Configuration</div>
        </template>
        <template #content>
          <div class="flex flex-column gap-1 w-full md:w-6 lg:w-4">
            <label for="subseller-input">Subseller (optional):</label>
            <InputText
              v-model="subseller"
              placeholder="Enter subseller"
              id="subseller-input"
            />
          </div>

          <SellerConfigComponent
            v-if="!!partnerId"
            :sellerId="partnerId"
            :subsellerId="subseller"
          />
        </template>
      </Card>

      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { emailValidator, domainValidator, passwordValidator } from "@/helpers";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Card from "primevue/card";
import SelectButton from "primevue/selectbutton";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import SellerConfigComponent from "./Config.vue";

export default {
  components: {
    InputText,
    InputNumber,
    Dropdown,
    Button,
    SellerConfigComponent,
    Card,
    SelectButton,
  },
  setup() {
    const store = useStore();

    const toast = useToast();

    const submitted = ref(false);
    const form = ref({
      name: "",
      email: "",
      domain: "",
      contractType: "",
      contractValue: 0,
      password: "",
      fixImpCpm: 0,
      can_manage_demand_tags: false,
      can_embed_new_widget: false,
      default_widget_layout: "0",
    });

    const router = useRouter();
    const partnerId = router.currentRoute.value.params.partnerId;
    const subseller = ref("");
    const canManageDemandTagsOptions = ref([
      { name: "Disabled", value: false },
      { name: "Allowed", value: true },
    ]);

    const getPartnerData = async () => {
      try {
        store.commit("setLoading", true);
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(
          API_BASE_URL + "/partner/" + partnerId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          const {
            name,
            email,
            domain,
            contract_type,
            contract_value,
            fix_imp_cpm,
            can_manage_demand_tags,
            can_embed_new_widget,
            default_widget_layout,
          } = response.data.partner;

          form.value.name = name;
          form.value.email = email;
          form.value.domain = domain;
          form.value.contractType = contract_type;
          form.value.contractValue =
            contract_type === "cpm" ? contract_value : contract_value * 100;
          form.value.fixImpCpm = fix_imp_cpm;
          form.value.can_manage_demand_tags = can_manage_demand_tags;
          form.value.can_embed_new_widget = can_embed_new_widget;
          form.value.default_widget_layout = default_widget_layout;
        }
      } catch (error) {
        console.error(error);
      }
      store.commit("setLoading", false);
    };

    if (partnerId) {
      form.value.partnerId = partnerId;
      getPartnerData();
    }

    const contractTypes = [
      { label: "Per Load", value: "cpm" },
      { label: "Revenue Share", value: "revshare" },
    ];

    const emailValid = computed(() => emailValidator(form.value.email));
    const domainValid = computed(() => domainValidator(form.value.domain));
    const passwordValid = computed(() =>
      passwordValidator(form.value.password)
    );

    const onContractTypeChange = () => {
      form.value.contractValue = 0;
    };

    const contractValueLabel = computed(() => {
      if (form.value.contractType === "cpm") {
        return "CPM Rate";
      } else {
        return "Share Percent";
      }
    });

    const submitForm = async () => {
      submitted.value = true;
      emailValid.value = emailValidator(form.value.email);
      domainValid.value = domainValidator(form.value.domain);
      passwordValid.value = passwordValidator(form.value.password);

      console.log("PARTNERID:", form.value.partnerId);

      if (
        form.value.name &&
        form.value.email &&
        form.value.domain &&
        form.value.contractType &&
        form.value.contractValue &&
        emailValid.value &&
        domainValid.value &&
        (form.value.partnerId || passwordValid.value)
      ) {
        try {
          store.commit("setLoading", true);
          const token = localStorage.getItem("adminToken");

          const response = await axios.post(
            API_BASE_URL + "/add-partner",
            form.value,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data?.success) {
            // Clear form after successful submission
            form.value = {
              name: "",
              email: "",
              domain: "",
              contractType: "",
              contractValue: 0,
              password: "",
              fixImpCpm: 0,
              can_manage_demand_tags: false,
              can_embed_new_widget: false,
              default_widget_layout: "0",
            };

            submitted.value = false;
            toast.add({
              severity: "success",
              summary: "Success",
              detail: `Partner ${
                partnerId ? "updated" : "added"
              } successfully!`,
              life: 4000,
            });
            router.replace({ name: "PartnersList" });
          } else {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: `Something went wrong...`,
              life: 4000,
            });
          }
        } catch (error) {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: `Something went wrong...`,
            life: 4000,
          });
          console.log(error);
        } finally {
          store.commit("setLoading", false);
        }
      }
    };

    return {
      form,
      contractTypes,
      onContractTypeChange,
      contractValueLabel,
      submitted,
      emailValid,
      domainValid,
      passwordValid,
      submitForm,
      partnerId,
      subseller,
      router,
      canManageDemandTagsOptions,
    };
  },
};
</script>
