<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{
        $router.currentRoute.value.name == "UpdatePodcastShow"
          ? "Update"
          : "Add"
      }}
      Podcast Show
    </h2>
    <form @submit.prevent="submitForm">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Show Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div
              v-if="isAdminRoute"
              class="col-12 md:col-6 flex flex-column gap-1"
            >
              <label for="podcaster">Podcaster</label>
              <Dropdown
                id="podcaster"
                v-model="form.podcaster_id"
                :options="podcasters"
                optionLabel="name"
                optionValue="podcaster_id"
                :filter="true"
                filterBy="name,email"
                placeholder="Select a podcaster"
                :class="{ 'p-invalid': submitted && !form.podcaster_id }"
              >
                <template #option="slotProps">
                  {{ slotProps.option.name }} ({{ slotProps.option.email }})
                </template>
              </Dropdown>
              <small v-if="submitted && !form.podcaster_id" class="p-error">
                Podcaster is required
              </small>
            </div>

            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="rss_url">RSS link </label>
              <InputText
                :disabled="showId"
                id="rss_url"
                v-model="form.rss_url"
                :class="{ 'p-invalid': submitted && !form.rss_url }"
              ></InputText>
              <small v-if="submitted && !form.rss_url" class="p-error"
                >RSS link is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="avg_per_month">Avg Downloads Per Month </label>
              <InputText
                :disabled="showId"
                id="avg_per_month"
                v-model="form.avg_per_month"
                :class="{ 'p-invalid': submitted && !form.avg_per_month }"
              ></InputText>
              <small v-if="submitted && !form.avg_per_month" class="p-error"
                >Avg download per month is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="avg_per_episode">Avg Downloads Per Episode </label>
              <InputText
                :disabled="showId"
                id="avg_per_episode"
                v-model="form.avg_per_episode"
                :class="{ 'p-invalid': submitted && !form.avg_per_episode }"
              ></InputText>
              <small v-if="submitted && !form.avg_per_episode" class="p-error"
                >Avg download per episode is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="spotify_link">Spotify link </label>
              <InputText
                id="spotify_link"
                v-model="form.spotify_link"
              ></InputText>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="itunes_link">iTunes link </label>
              <InputText
                id="itunes_link"
                v-model="form.itunes_link"
              ></InputText>
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";

const router = useRouter();
const store = useStore();
const toast = useToast();

const isAdminRoute = computed(
  () => router.currentRoute.value.name === "AdminAddPodcastShow"
);

const submitted = ref(false);
const form = ref({
  rss_url: "",
  spotify_link: "",
  itunes_link: "",
  avg_per_episode: 0,
  avg_per_month: 0,
  podcaster_id: null, // New field for admin to select podcaster
});

const showId = router.currentRoute.value.params.showId;
const token = localStorage.getItem(
  isAdminRoute.value ? "podcastAdminToken" : "podcasterToken"
);
const podcasters = ref([]);

const fetchPodcasters = async () => {
  if (isAdminRoute.value) {
    // await store.dispatch("loadPodcasters", { token });
    podcasters.value = store.getters.podcasters;
  }
};

const getShowData = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(
      API_BASE_URL + "/podcaster/shows/" + showId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success) {
      const {
        rss_url,
        spotify_link,
        itunes_link,
        avg_per_episode,
        avg_per_month,
      } = response.data.show;
      form.value = {
        ...form.value,
        rss_url,
        spotify_link,
        itunes_link,
        avg_per_episode,
        avg_per_month,
      };
    } else
      toast.add({
        severity: "error",
        summary: "Show data not found",
        life: 4000,
      });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Something went wrong...",
      life: 4000,
    });
  }
  store.commit("setLoading", false);
};

const submitForm = async () => {
  submitted.value = true;
  if (
    form.value.rss_url &&
    form.value.avg_per_episode &&
    form.value.avg_per_month
  ) {
    try {
      store.commit("setLoading", true);

      const response = await axios.post(
        API_BASE_URL + "/podcaster/add-show",
        form.value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data?.success) {
        // Clear form after successful submission
        form.value = {
          rss_url: "",
          spotify_link: "",
          itunes_link: "",
          avg_per_episode: 0,
          avg_per_month: 0,
        };

        submitted.value = false;
        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Show ${showId ? "updated" : "added"} successfully!`,
          life: 4000,
        });
        router.replace({
          name: isAdminRoute ? "PodcastAdminShowsList" : "PodcastShowsList",
        });
      } else {
        toast.add({
          severity: "error",
          detail: `Something went wrong...`,
          life: 4000,
        });
      }
    } catch (error) {
      toast.add({
        severity: "error",
        detail: `Something went wrong...`,
        life: 4000,
      });
      console.log(error);
    } finally {
      store.commit("setLoading", false);
    }
  }
};

onMounted(() => {
  fetchPodcasters();
  if (showId) {
    form.value.show_id = showId;
    getShowData();
  }
});
</script>
