<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Dashboard</h2>
    <div class="mb-4 flex flex-column sm:flex-row gap-2 sm:gap-3">
      <Calendar
        v-model="dateRange"
        :showIcon="true"
        :number-of-months="2"
        :selectionMode="'range'"
        @update:modelValue="setDateRange"
      >
      </Calendar>

      <Button @click="fetchOnDateRange">Fetch Data</Button>
    </div>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Partner Daily</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            class="pb-4"
            :data="chartData"
            :options="{ responsive: true, maintainAspectRatio: false }"
          ></line-chart>
          <LoaderSm v-if="chartLoading.partner_daily" />
        </div>
        <DataTable
          :value="dailyData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.daily"
          lazy
          @page="(e) => fetchData('partner_daily', e)"
          @sort="(e) => fetchData('partner_daily', e)"
          :loading="tableLoading.partner_daily"
        >
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          ></Column>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.partnerDaily" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Domain</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            class="pb-4"
            :data="chartDataPerDomain"
            :options="{ responsive: true, maintainAspectRatio: false }"
            legend="bottom"
          ></line-chart>
          <LoaderSm v-if="chartLoading.partner_per_domain_daily" />
        </div>
        <DataTable
          :value="perDomainData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.domainly"
          lazy
          @page="(e) => fetchData('partner_per_domain_daily', e)"
          @sort="(e) => fetchData('partner_per_domain_daily', e)"
          :loading="tableLoading.partner_per_domain_daily"
        >
          <Column
            v-for="col in columnsPerDomain"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_per_domain_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.partnerPerDomainDaily"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Group Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="perGroupMonthlyData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.groupMonthly"
          lazy
          @page="(e) => fetchData('partner_per_group_monthly', e)"
          @sort="(e) => fetchData('partner_per_group_monthly', e)"
          :loading="tableLoading.partner_per_group_monthly"
        >
          <Column
            v-for="col in columnsPerGroupMonthly"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_per_group_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.partnerPerGroupMonthly"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Geo Location</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            class="pb-4"
            :data="chartDataPerGeo"
            :options="{ responsive: true, maintainAspectRatio: false }"
            legend="{display: false}"
          ></line-chart>
          <LoaderSm v-if="chartLoading.partner_per_geo_daily" />
        </div>
        <DataTable
          :value="perGeoData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.geoly"
          lazy
          @page="(e) => fetchData('partner_per_geo_daily', e)"
          @sort="(e) => fetchData('partner_per_geo_daily', e)"
          :loading="tableLoading.partner_per_geo_daily"
        >
          <Column
            v-for="col in columnsPerGeo"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_per_geo_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.partnerPerGeoDaily"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card>
      <template #title>
        <div class="text-xl">Partner Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="monthlyData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.monthly"
          lazy
          @page="(e) => fetchData('partner_monthly', e)"
          @sort="(e) => fetchData('partner_monthly', e)"
          :loading="tableLoading.partner_monthly"
        >
          <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          ></Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('partner_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.partnerMonthly" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Card from "primevue/card";

import { useStore } from "vuex";
import { formatCurrencyNumber, adjustDate } from "../helpers";
import LoaderSm from "./LoaderSm.vue";

export default {
  components: {
    Calendar,
    Button,
    Card,
    LoaderSm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const columns = [
      { header: "Date", field: "date_trunc" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "ECPM", field: "ecpm" },
    ];
    const columnsPerDomain = [
      { header: "Date", field: "date_trunc" },
      { header: "Domain", field: "url" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "ECPM", field: "ecpm" },
    ];
    const columnsPerGeo = [
      { header: "Date", field: "date_trunc" },
      { header: "Geo", field: "geo" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "ECPM", field: "ecpm" },
    ];
    const columnsPerGroupMonthly = [
      { header: "Date", field: "date_trunc" },
      { header: "Group", field: "group_name" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "ECPM", field: "ecpm" },
    ];
    const rows = ref(10);
    const totalRecords = ref({
      daily: 0,
      monthly: 0,
      domainly: 0,
      geoly: 0,
      groupMonthly: 0,
    });
    const first = ref({ daily: 0, monthly: 0 });

    const monthlyData = ref([]);
    const dailyData = ref([]);

    const chartData = ref({});
    const chartDataPerDomain = ref({});
    const chartDataPerGeo = ref({});
    const perDomainData = ref([]);
    const perGroupMonthlyData = ref([]);
    const perGeoData = ref([]);
    const csvData = ref({
      partnerDaily: [],
      partnerMonthly: [],
      partnerPerDomainDaily: [],
      partnerPerGeoDaily: [],
      partnerPerGroupMonthly: [],
    });

    const tableLoading = ref({
      partner_daily: false,
      partner_monthly: false,
      partner_per_domain_daily: false,
      partner_per_geo_daily: false,
      partner_per_group_monthly: false,
    });

    const chartLoading = ref({
      partner_daily: false,
      partner_per_domain_daily: false,
      partner_per_geo_daily: false,
    });

    // Get today's date
    var today = ref(new Date());
    today.value.setHours(0, 0, 0, 0); // Set time to 00:00:00

    // Get the date 30 days ago
    var thirtyDaysAgo = ref(new Date());
    thirtyDaysAgo.value.setDate(thirtyDaysAgo.value.getDate() - 7);
    thirtyDaysAgo.value.setHours(0, 0, 0, 0); // Set time to 00:00:00

    const dateRange = ref([thirtyDaysAgo.value, today.value]);

    const setDateRange = (value) => {
      dateRange.value = value;
    };

    const finishLoading = (dataMode) => {
      if (dataMode.includes("all"))
        chartLoading.value[dataMode.slice(0, -4)] = false;
      else tableLoading.value[dataMode] = false;
    };

    const fetchData = async (
      dataMode,
      { page = 0, rows = 5, sortField, sortOrder }
    ) => {
      try {
        if (dataMode.includes("all"))
          chartLoading.value[dataMode.slice(0, -4)] = true;
        else tableLoading.value[dataMode] = true;
        const partnerId = router.currentRoute.value.params.partnerId;

        let token = "";
        if (partnerId) {
          token = localStorage.getItem("adminToken");
        } else {
          token = localStorage.getItem("token");
        }

        const startDate = adjustDate(dateRange.value?.[0]);
        const endDate = adjustDate(dateRange.value?.[1]);

        const params = {
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
          dataMode,
          page,
          rows,
          sortField,
          sortOrder,
        };

        if (partnerId) {
          params.partnerId = partnerId;
        }

        const { data } = await axios.get(API_BASE_URL + "/dashboard", {
          params,
          headers: { Authorization: `Bearer ${token}` },
        });

        if (data.success) {
          if (data.data?.partnerMonthly?.length) {
            if (dataMode == "partner_monthly") {
              totalRecords.value.monthly = parseInt(
                data.data.partnerMonthly[0].count
              );
              monthlyData.value = data.data.partnerMonthly.map((item) => {
                return {
                  date_trunc: item.date_trunc,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });
            } else {
              csvData.value.partnerMonthly = data.data.partnerMonthly;
            }
          } else if (dataMode == "partner_monthly") {
            totalRecords.value.monthly = 0;
            monthlyData.value = [];
          }
          if (data.data?.partnerPerGroupMonthly?.length) {
            if (dataMode == "partner_per_group_monthly") {
              totalRecords.value.groupMonthly = parseInt(
                data.data.partnerPerGroupMonthly[0].count
              );
              perGroupMonthlyData.value = data.data.partnerPerGroupMonthly.map(
                (item) => {
                  return {
                    date_trunc: item.date_trunc,
                    group_name: item.group_name,
                    loads: item.loads.toLocaleString(),
                    impressions: item.impressions.toLocaleString(),
                    revenue: formatCurrencyNumber(item.revenue),
                    cost: formatCurrencyNumber(item.cost),
                    profit: formatCurrencyNumber(item.profit),
                    ecpm: formatCurrencyNumber(item.ecpm),
                  };
                }
              );
            } else {
              csvData.value.partnerPerGroupMonthly =
                data.data.partnerPerGroupMonthly;
            }
          } else if (dataMode == "partner_per_group_monthly") {
            totalRecords.value.groupMonthly = 0;
            perGroupMonthlyData.value = [];
          }
          if (data.data?.partnerDaily?.length) {
            if (dataMode == "partner_daily") {
              totalRecords.value.daily = parseInt(
                data.data.partnerDaily[0].count
              );
              dailyData.value = data.data.partnerDaily.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });
            } else {
              csvData.value.partnerDaily = data.data.partnerDaily;
              const dailyChartData = data.data.partnerDaily.map((item) => {
                return [item.date_trunc, item.revenue.toFixed(2)];
              });

              chartData.value = dailyChartData;
            }
          } else if (dataMode == "partner_daily") {
            totalRecords.value.daily = 0;
            dailyData.value = [];
            chartData.value = [];
          }
          if (data.data?.partnerPerDomainDaily?.length) {
            if (dataMode == "partner_per_domain_daily") {
              totalRecords.value.domainly = parseInt(
                data.data.partnerPerDomainDaily[0].count
              );
              perDomainData.value = data.data.partnerPerDomainDaily;

              perDomainData.value = perDomainData.value.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  url: item.url,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });
            } else {
              csvData.value.partnerPerDomainDaily =
                data.data.partnerPerDomainDaily;
              const perDomainChartData = {};

              let perDomainDataChart = data.data.partnerPerDomainDaily;

              perDomainDataChart = perDomainDataChart.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  url: item.url,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });

              for (let i = 0; i < perDomainDataChart.length; i++) {
                const domainData = perDomainDataChart[i];

                if (!perDomainChartData[domainData.url]) {
                  perDomainChartData[domainData.url] = {};
                }

                perDomainChartData[domainData.url][domainData.date_trunc] =
                  domainData.revenue;
              }

              chartDataPerDomain.value = Object.entries(perDomainChartData).map(
                ([url, data]) => ({
                  name: url,
                  data: Object.entries(data).reduce((acc, [date, price]) => {
                    acc[date] = parseFloat(price.replace(/[$,]/g, ""));
                    return acc;
                  }, {}),
                })
              );
            }
          } else if (dataMode == "partner_per_domain_daily") {
            totalRecords.value.domainly = 0;
            chartDataPerDomain.value = [];
          }
          if (data.data?.partnerPerGeoDaily?.length) {
            if (dataMode == "partner_per_geo_daily") {
              totalRecords.value.geoly = parseInt(
                data.data.partnerPerGeoDaily[0].count
              );
              perGeoData.value = data.data.partnerPerGeoDaily;

              perGeoData.value = perGeoData.value.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  geo: item.geo,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });
            } else {
              csvData.value.partnerPerGeoDaily = data.data.partnerPerGeoDaily;
              const perGeoChartData = {};

              let perGeoDataChart = data.data.partnerPerGeoDaily;

              perGeoDataChart = perGeoDataChart.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  geo: item.geo,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  ecpm: formatCurrencyNumber(item.ecpm),
                };
              });

              for (let i = 0; i < perGeoDataChart.length; i++) {
                const geoData = perGeoDataChart[i];

                if (!perGeoChartData[geoData.geo]) {
                  perGeoChartData[geoData.geo] = {};
                }

                perGeoChartData[geoData.geo][geoData.date_trunc] =
                  geoData.revenue;
              }

              chartDataPerGeo.value = Object.entries(perGeoChartData).map(
                ([geo, data]) => ({
                  name: geo,
                  data: Object.entries(data).reduce((acc, [date, price]) => {
                    acc[date] = parseFloat(price.replace(/[$,]/g, ""));
                    return acc;
                  }, {}),
                })
              );
            }
          } else if (dataMode == "partner_per_geo_daily") {
            totalRecords.value.geoly = 0;
            chartDataPerGeo.value = [];
          }
        }
      } catch (error) {
        // Handle errors
        console.log(error);
      } finally {
        finishLoading(dataMode);
      }
    };

    const fetchOnDateRange = () => {
      fetchData("partner_daily", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_daily_all", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_per_domain_daily", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_per_domain_daily_all", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_per_geo_daily", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_per_geo_daily_all", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_monthly", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_monthly_all", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_per_group_monthly", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
      fetchData("partner_per_group_monthly_all", {
        page: 0,
        rows: rows.value,
        sortField: null,
        sortOrder: null,
      });
    };

    onMounted(() => {
      fetchOnDateRange();
    });

    return {
      columns,
      monthlyData,
      dailyData,
      fetchData,
      fetchOnDateRange,
      chartData,
      dateRange,
      setDateRange,
      rows,
      totalRecords,
      tableLoading,
      chartLoading,
      first,
      chartDataPerDomain,
      chartDataPerGeo,
      perDomainData,
      perGroupMonthlyData,
      perGeoData,
      columnsPerDomain,
      columnsPerGeo,
      columnsPerGroupMonthly,
      csvData,
    };
  },
};
</script>
