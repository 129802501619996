<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Filtered Tags List</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            placeholder="Seller ID"
            v-model="sellerId"
          />
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            placeholder="Sub-Seller ID"
            v-model="subSellerId"
          />
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            placeholder="Geo"
            v-model="geo"
          />
          <Button label="Fetch Tags" @click="fetchFilteredTags" />
        </div>
      </template>
      <template #content>
        <DataTable :value="filteredTags" class="p-datatable-sm">
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="p" header="Name" :sortable="true"></Column>
          <!-- Add other columns as needed -->
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { API_BASE_URL } from "../consts";

const sellerId = ref("");
const subSellerId = ref("");
const geo = ref("");
const filteredTags = ref([]);

const fetchFilteredTags = async () => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await axios.get(`${API_BASE_URL}/admin/tags-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        sellerId: sellerId.value,
        subSellerId: subSellerId.value,
        geo: geo.value,
      },
    });

    if (response.data && response.data.tags) {
      filteredTags.value = response.data.tags;
    }
  } catch (error) {
    console.error("Error fetching filtered tags:", error);
  }
};
</script>

<style scoped>
/* Add any specific styles you need here */
</style>
