<template>
  <div :class="{ 'blurred-background': editDialogVisible }">
    <h2 class="mt-0" style="color: var(--text-color)">{{ title }}</h2>

    <Card>
      <template #header>
        <div v-if="pendingSellersCount > 0" class="p-3 pb-0">
          <Message severity="info"
            >You have {{ pendingSellersCount }} new sellers to approve</Message
          >
        </div>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button
            size="small"
            class="justify-content-center"
            label="Add New Seller"
            icon="pi pi-plus"
            @click="openSelectPartner"
          />
          <InputText
            class="w-full sm:w-24rem"
            type="text"
            v-model="sellersTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="sellers"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="sellersTablesFilters"
          :sort-field="'id'"
          :sort-order="-1"
        >
          <Column field="partner_id" header="Partner" :sortable="true"></Column>
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="domain" header="Domain" :sortable="true">
            <template #body="slotProps">
              <a
                class="no-style-link"
                :href="formatDomainURL(slotProps.data.domain)"
                target="_blank"
              >
                {{ slotProps.data.domain }}
              </a>
            </template>
          </Column>

          <Column field="group_name" header="Group" :sortable="true"></Column>
          <Column field="adstxt_verified" header="ads.txt" :sortable="true">
            <template #body="slotProps">
              <AdstxtValResultTag :data="slotProps.data" />
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <Badge
                :severity="
                  slotProps.data.status === 'approved'
                    ? 'success'
                    : slotProps.data.status === 'declined'
                    ? 'danger'
                    : 'warning'
                "
                :value="slotProps.data.status"
                class="capitalize border-round-3xl"
              />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex gap-1 justify-content-end">
                <Button
                  v-if="slotProps.data.status == 'approved'"
                  icon="pi pi-code"
                  outlined
                  rounded
                  severity="success"
                  @click="openGenerateTagDialog(slotProps.data)"
                />
                <Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success p-button-outlined"
                  @click="confirmBeforeAction(slotProps.data.id, true)"
                  :disabled="slotProps.data.status === 'approved'"
                  aria-label="Approve"
                />
                <Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-outlined"
                  @click="confirmBeforeAction(slotProps.data.id, false)"
                  :disabled="slotProps.data.status === 'declined'"
                  aria-label="Decline"
                />
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-outlined"
                  @click="openEditDialog(slotProps.data)"
                  aria-label="Edit"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>

  <Dialog
    v-model:visible="editDialogVisible"
    @hide="closeEditDialog"
    :style="{ width: '75vw' }"
  >
    <AdminSellerEdit
      :sellerid="selectedSeller.id"
      :partnerid="selectedSeller.partner_id"
      :tagbl="selectedSeller.tags_bl"
      :tagwl="selectedSeller.tags_wl"
      :isdemo="
        configObject[selectedSeller.partner_id]?.bbp_disable === 1 ||
        configObject[`${selectedSeller.partner_id}_${selectedSeller.id}`]
          ?.bbp_disable === 1
      "
      @update-success="handleUpdateSuccess"
      @close-dialog="closeEditDialog"
    />
  </Dialog>
  <Dialog
    v-model:visible="selectPartnerDialogVisible"
    class="w-screen max-w-30rem"
    @hide="closeSelectPartner"
    :closable="true"
    modal
  >
    <div class="flex flex-column">
      <label for="selectpartner">Select Partner</label>
      <Dropdown
        id="selectpartner"
        v-model="selectedPartner"
        :options="partners"
        placeholder="Select Partner"
        optionLabel="name"
        optionValue="id"
      />
    </div>
    <div class="pt-4 w-full flex justify-content-end">
      <Button
        size="small"
        class="justify-content-center"
        label="Add New Seller"
        icon="pi pi-plus"
        @click="goToAddNewSeller"
      />
    </div>
  </Dialog>
  <GenerateTagDialog
    v-model:visible="showGenerateTagDialog"
    :selectedSeller="selectedSeller"
    :partner="selectedPartner"
    @close-dialog="showGenerateTagDialog = false"
    @hide="showGenerateTagDialog = false"
    :adsTxtContent="adsTxtContent"
  />
</template>

<script>
// The rest of the code goes here
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { useRouter } from "vue-router";
import { API_BASE_URL } from "../consts";
import { FilterMatchMode } from "primevue/api";
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Badge from "primevue/badge";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Message from "primevue/message";
import AdstxtValResultTag from "./AdstxtValResult/AdstxtValResultTag.vue";
import Dialog from "primevue/dialog";
import AdminSellerEdit from "./AdminSellerEdit.vue";
import { isWithinOneWeek } from "../helpers";
import Dropdown from "primevue/dropdown";
import GenerateTagDialog from "@/components/GenerateTagDialog.vue";

export default {
  components: {
    InputText,
    Card,
    Button,
    Badge,
    AdstxtValResultTag,
    Dialog,
    AdminSellerEdit,
    Message,
    Dropdown,
    GenerateTagDialog,
  },
  setup() {
    const sellers = ref([]);
    const partners = ref([]);
    const store = useStore();
    const confirm = useConfirm();
    const toast = useToast();
    const router = useRouter();
    const sellersTablesFilters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const title = "Admin Sellers";
    const token = localStorage.getItem("adminToken");

    const editDialogVisible = ref(false);
    const selectPartnerDialogVisible = ref(false);
    const showGenerateTagDialog = ref(false);
    const selectedSeller = ref({});
    const pendingSellersCount = ref(0);
    const configObject = ref({});
    const selectedPartner = ref(null);
    const adsTxtContent = ref("");

    const openGenerateTagDialog = (seller) => {
      selectedPartner.value = partners.value.find(
        (item) => item.id == seller.partner_id
      );
      selectedSeller.value = seller;
      showGenerateTagDialog.value = true;
    };

    const goToAddNewSeller = () => {
      if (!selectedPartner.value) return;

      router.push({
        name: "AdminAddSeller",
        query: { partnerId: selectedPartner.value },
      });
    };

    const openSelectPartner = () => {
      selectedPartner.value = null;
      selectPartnerDialogVisible.value = true;
    };

    const closeSelectPartner = () => {
      selectedPartner.value = null;
      selectPartnerDialogVisible.value = false;
    };

    const openEditDialog = (seller) => {
      selectedSeller.value = seller;
      editDialogVisible.value = true;
    };

    const closeEditDialog = () => {
      editDialogVisible.value = false;
    };

    const handleUpdateSuccess = () => {
      closeEditDialog();
      //fetchSellers(); // Refresh the sellers list
    };

    const goToEdit = (seller_id, partner_id, tags_bl, tags_wl) => {
      router.push({
        name: "AdminSellerEdit",
        query: {
          sellerid: seller_id,
          partnerid: partner_id,
          tags_bl,
          tags_wl,
        },
      });
    };

    const updateSellerStatus = async (sellerId, status) => {
      try {
        const response = await axios.put(
          API_BASE_URL + "/admin/sellers/" + sellerId + "/status",
          { status: status },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status == 200) {
          // update the status in the local data
          const seller = sellers.value.find((s) => s.id === sellerId);
          if (seller) {
            seller.status = status;
            toast.add({
              severity: "info",
              summary: `Seller ${status}`,
              detail: `Seller has been ${status} successfully.`,
              life: 4000,
            });
          }
        }
      } catch (error) {
        console.error(`Error updating seller status to ${status}:`, error);
      }
    };

    const confirmBeforeAction = (sellerId, actionType) => {
      confirm.require({
        message: `Are you sure you want to ${
          actionType ? "approve" : "decline"
        } this connection?`,
        header: "Confirmation",
        icon: "pi pi-info-circle",
        acceptIcon: `pi pi-${actionType ? "check-circle" : "ban"}`,
        acceptClass: `p-button-${actionType ? "info" : "danger"}`,
        acceptLabel: actionType ? "Approve" : "Decline",
        accept: () => {
          updateSellerStatus(sellerId, actionType ? "approved" : "declined");
        },
      });
    };

    const fetchConfig = async () => {
      try {
        store.commit("setLoading", true);
        const resFromDB = await axios.get(API_BASE_URL + "/get-config", {
          headers: { Authorization: `Bearer ${token}` },
        });
        configObject.value = resFromDB.data;
      } catch (error) {
        console.log(error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchAdsTxt = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "/adstxt", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.result) {
          adsTxtContent.value = response.data.result.value;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchPartners = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/partners", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          partners.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching partners:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchSellers = async () => {
      try {
        store.commit("setLoading", true);

        const response = await axios.get(API_BASE_URL + "/sellers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          sellers.value = response.data;
          pendingSellersCount.value = sellers.value.filter(
            (item) =>
              item.status == "pending" && isWithinOneWeek(item.updated_at)
          ).length;
        }
      } catch (error) {
        console.error("Error fetching sellers:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const formatDomainURL = (domain) => {
      if (domain.startsWith("http://") || domain.startsWith("https://")) {
        return domain;
      }
      return `https://${domain}`;
    };

    onMounted(fetchSellers);
    onMounted(fetchPartners);
    onMounted(fetchConfig);
    onMounted(fetchAdsTxt);

    return {
      sellers,
      confirmBeforeAction,
      goToAddNewSeller,
      openSelectPartner,
      closeSelectPartner,
      goToEdit,
      sellersTablesFilters,
      title,
      editDialogVisible,
      selectPartnerDialogVisible,
      selectedSeller,
      openEditDialog,
      closeEditDialog,
      handleUpdateSuccess,
      formatDomainURL,
      pendingSellersCount,
      configObject,
      selectedPartner,
      partners,
      selectedSeller,
      showGenerateTagDialog,
      openGenerateTagDialog,
      adsTxtContent,
    };
  },
};
</script>

<style>
.blurred-background {
  filter: blur(2px);
  /* Add more styling as needed to achieve the desired effect */
}
.no-style-link {
  color: inherit; /* Makes the link color the same as the surrounding text */
  text-decoration: none; /* Removes the underline from the link */
}
</style>
