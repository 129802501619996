<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Ads.txt Content</h2>
    <Card class="mb-3">
      <template #title>
        <div class="text-xl">Update Ads.txt Content Below</div>
      </template>
      <template #content>
        <Textarea
          v-model="adsTxt.edited"
          rows="10"
          auto-resize
          class="w-full"
        />
      </template>
      <template #footer>
        <div class="w-full flex justify-content-end gap-2">
          <Button
            class="flex-1 sm:flex-initial"
            outlined
            label="Revert"
            @click="handleRevertContent"
          />
          <Button
            class="flex-1 sm:flex-initial"
            label="Save"
            @click="handleAdsTxtSave"
          />
        </div>
      </template>
    </Card>
    <Dialog
      v-model:visible="showGroupsModal"
      header="Select Groups to Notify"
      class="w-full max-w-30rem"
      :closable="false"
      :modal="true"
    >
      <div class="flex flex-column gap-1 mb-3">
        <label for="groups_list">Select Groups:</label>
        <MultiSelect
          input-id="groups_list"
          v-model="selectedGroupsForNotify"
          :options="groupListOptions"
          option-label="name"
          placeholder="Select Groups"
          option-value="id"
          filter
          filter-placeholder="Search Groups"
        />
      </div>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          @click="submitAdsTxt"
          label="Finish"
        />
      </div>
    </Dialog>
  </div>
</template>
<script setup>
import axios from "axios";
import Card from "primevue/card";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import { ref } from "vue";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";

const store = useStore();
const toast = useToast();
const token = localStorage.getItem("adminToken");

const adsTxt = ref({
  original: "",
  edited: "",
});

const groupListOptions = ref([]);
const selectedGroupsForNotify = ref([]);
const showGroupsModal = ref(false);

const handleAdsTxtSave = () => {
  showGroupsModal.value = true;
};

const handleRevertContent = () => {
  adsTxt.value.edited = adsTxt.value.original;
};

const fetchAdsTxt = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(API_BASE_URL + "/adstxt", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.result) {
      const adsTxtValue = response.data.result.value;
      adsTxt.value.original = adsTxtValue;
      adsTxt.value.edited = adsTxtValue;
    }
  } catch (error) {
    console.log(error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchGroupList = async () => {
  try {
    const response = await axios.get(API_BASE_URL + "/member-list/group", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      groupListOptions.value = response.data.groups;
    }
  } catch (error) {
    console.log(error);
  }
};

const submitAdsTxt = async () => {
  try {
    showGroupsModal.value = false;
    store.commit("setLoading", true);
    const response = await axios.post(
      API_BASE_URL + "/adstxt",
      {
        value: adsTxt.value.edited,
        groups_to_notify: selectedGroupsForNotify.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Successfully updated Ads.txt Content.",
        life: 4000,
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong, couldn't update the Ads.txt content.",
        life: 4000,
      });
    }
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Something went wrong, couldn't update the Ads.txt content.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
    selectedGroupsForNotify.value = [];
  }
};

onBeforeMount(() => {
  fetchAdsTxt();
  fetchGroupList();
});
</script>
